import React from 'react';
import {Card, Col, Row} from "antd";
import {properties} from "../../properties";
import {ArcherContainer, ArcherElement} from "react-archer";
import {createDashboardLink, getProjectId, openLinkInNewTab} from "../../helpers";

export class StaticDashboard extends React.Component {
    render() {

        let projectId = getProjectId(this.props.location)

        const mainCategories = [{
            name: 'Spare Parts',
            className: 'Spare Parts',
            link: createDashboardLink(projectId, 'Spare Parts')
        }]
        const spCategories = [
            {name: 'Turnover', className: 'Turnover', link: createDashboardLink(projectId, '65958528f947faaa65f90c52')},
            {name: 'Capital Spare', className: 'Capital Spare', link: createDashboardLink(projectId, '65f134e721c1dc118994d5d6')},
            {
                name: 'Insurance Spare',
                className: 'Insurance Spare',
                link: createDashboardLink(projectId, '659584f1f947faaa65f90c35')
            },
            {name: 'Common', className: 'Common', link: createDashboardLink(projectId, '65cd4d5ee5d1b9b7f404fc97')},
            {name: 'Actual Obsolete', className: 'Actual Obsolete', link: createDashboardLink(projectId, '')},
            {
                name: 'Potential Obsolete',
                className: 'Potential Obsolete',
                link: createDashboardLink(projectId, '659fe24649f4266fe7ea4a91')
            },
            {
                name: 'Potential Duplicate',
                className: 'Potential Duplicate',
                link: createDashboardLink(projectId, '65c9cee049b6df1bcdae59e7')
            },
            {
                name: 'No BOM Items',
                className: 'No BOM Items',
                link: createDashboardLink(projectId, '65cc16dbbf3dacd5ab8971fa')
            }]
        const typeCategories = [
            {name: 'High Turnover', className: 'High Turnover', link: createDashboardLink(projectId, 'High Turnover')},
            {name: 'Low Turnover', className: 'Low Turnover', link: createDashboardLink(projectId, 'Low Turnover')},
            {name: 'Slow-Moving', className: 'Slow-Moving', link: createDashboardLink(projectId, 'Slow-Moving')},
            {name: 'Non-Moving', className: 'Non-Moving', link: createDashboardLink(projectId, 'Non-Moving')}]

        const getRightRelationships = categoryName => {
            console.log(categoryName)
            let children = []
            if (categoryName === 'Spare Parts') {
                children = ['Turnover', 'Capital Spare', 'Insurance Spare', 'Common', 'Actual Obsolete', 'Potential Obsolete', 'Potential Duplicate', 'No BOM Items']
            } else if (categoryName === 'Turnover') {
                children = ['High Turnover', 'Low Turnover', 'Slow-Moving', 'Non-Moving']
            }

            return children.map(child => {
                return {
                    targetId: child,
                    targetAnchor: 'top',
                    sourceAnchor: 'bottom',
                }
            })
        }


        return <div>
            <ArcherContainer strokeColor={properties.kbrColors.lightBlue} noCurves={true}>
                <Row style={{padding: '5px'}}>

                    <Col xs={0} sm={2} md={5}/>
                    {mainCategories.map(category => <Col sm={4} md={4} lg={4} xl={4}>
                        <ArcherElement id={category.name} relations={getRightRelationships(category.name)}>
                            <Card className={`card-settings`}
                                  style={{marginLeft: 10, cursor: category.link && 'pointer'}}
                                  onClick={() => category.link && openLinkInNewTab(category.link)}>
                                <div style={{textAlign: 'center'}}>{category.name}</div>
                            </Card>
                        </ArcherElement>
                    </Col>)}
                </Row>
                <Row style={{padding: '5px', marginTop: 40}}>
                    {spCategories.map(category => <Col sm={4} md={3} lg={3} xl={3}>
                        <div className={category.className}>
                            <ArcherElement id={category.name} relations={getRightRelationships(category.name)}>
                                <Card className={`card-settings`}
                                      style={{marginLeft: 10, cursor: category.link && 'pointer'}}
                                      onClick={() => category.link && openLinkInNewTab(category.link)}>
                                    <div style={{textAlign: 'center'}}>{category.name}</div>
                                </Card>
                            </ArcherElement>
                        </div>
                    </Col>)}
                </Row>
                <Row style={{padding: '5px', marginTop: 40}}>
                    {typeCategories.map(category => <Col sm={4} md={3} lg={3} xl={3}>
                        <ArcherElement id={category.name} relations={getRightRelationships(category.name)}>
                            <Card className={`card-settings`}
                                  style={{marginLeft: 10, cursor: category.link && 'pointer'}}
                                  onClick={() => category.link && openLinkInNewTab(category.link)}>
                                <div style={{textAlign: 'center'}}>{category.name}</div>
                            </Card>
                        </ArcherElement>
                    </Col>)}

                </Row>
            </ArcherContainer>
        </div>
    }
}
