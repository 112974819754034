import React from 'react';
import {Tabs} from "antd";

const { TabPane } = Tabs;
export function CustomTabs ({defaultKey, tabsData, onChange, activeKey}) {

    const createTabs = () => {
        return tabsData.map(tab => {
            return <TabPane tab={tab.name} key={tab.key}>
                {tab.children}
            </TabPane>
        })
    }

    return <div>
        <Tabs defaultActiveKey={defaultKey}
              onChange={activeKey => onChange(activeKey)}
              activeKey={activeKey} style={{marginTop: -15}}>
            {createTabs()}
        </Tabs>
    </div>

}
