import 'antd/dist/antd.css';
import './Dashboard.css';
import {connect} from 'react-redux'
import React from "react";
import {Col, Divider, Layout, message, Row} from 'antd';
import {
    getDuplicatesItems,
    getOverviewInfo,
    getInsuranceItems, getNoBOMItems,
    getFilterValues,
    filter,
    getFullItems,
    getObsoleteItems,
    getSlowMovingItems,
    searchByMaterial, getSelectedExamples
} from "./actions/Requests";
import {Content} from "antd/es/layout/layout";
import {downloadExcel, openHalfLink} from "./helpers";
import {InfoPanel} from "./components/helpers/InfoPanel";
import {TablePanel} from "./components/helpers/TablePanel";
import {properties, show_obsoletes, showOnDashboard} from "./properties";
import * as Auth from "./AuthService";
import axios from "./api";
import {ModalWindow} from "./components/helpers/modals/ModalWindow";

export const dict = {
    'full': ["This overview summarises the number of lines of unique materials and values for all materials.",
    "Data is sourced from the MBEW, MARA, PO Text, and ZDI tables. \n" ,
        "Quantities are for unique materials only.  Materials located at several plants and several locations is counted as one material.   \n" ,
        "Value for Priced materials is calculated and value for Unpriced materials is estimated. \n" ,
        "Priced materials are materials with a price per unit > 0.01. \n",
        "Unpriced materials are materials with a price per unit <= 0.01. \n",
    <div>Value for Priced materials is calculated using the formula below.
        <ul>
            <li>Stock-on-Hand quantity x Price per Unit x Price Unit).</li>
            <li>E.g., for a Priced material with stock on hand of 10, price per unit of 20,000 and a price unit of 1,000, the Price is calculated as 10 x 20,000 ÷ 1,000 = 200.</li>
        </ul>
    </div>,
        "Value for Unpriced materials is estimated based on the total Value of Priced materials divided by the total Quantity of priced materials.  This estimate is only applied for Unpriced materials with Stock-on-Hand > 0.   \n"
        ,
        "The formula for estimating Unpriced Stocked lines is the total Value of Stocked Priced materials is 12.1B and the total quantity of Priced materials is 120,610, then the estimated price per unit = 12.1B ÷ 120,610 = 100,323 per line.  Therefore, each Unpriced material with stock on hand > 0 is estimated at 100,323.  If the total number of Unpriced materials with stock on hand > 0 is 5,839 lines, then total estimated value for Unpriced lines is 5,839 x 100,323 = 0.586B.  "],
    'insurance': ["A Capital Spare is a unit of equipment, assembly, or spare part, of significant value that is maintained in inventory for use if a similar piece of critical equipment fails or must be rebuilt. \n" ,
    "Insurance spares are major units of equipment, assemblies, and spare parts kept on hand to ensure the uninterrupted operation of production equipment if there is an unexpected breakdown or equipment failure. They do not include items that are generally consumed or replaced during the regular maintenance cycle. \n" ,
    "This capital spares section summarises the number of lines and values of both stocked and unstocked capital spares. \n" ,
    "The criteria for capital spares are user defined and currently set at any material with a unit price >= 1.725M THB.\n" ,
    "Other criteria can be added, including long lead times (e.g., >=180 days) to further refine the definition. \n" ,
    "Note that there may be stocked capital spares missing from this analysis if those spares are not catalogued or catalogued but not priced. \n" ,
    "Quantities are for unique materials only.  Materials located at several plants and several locations is counted as one material.   \n" ,
    "There are no estimated values for capital spares that are Unpriced. \n" ,
    `See ${showOnDashboard} Overview box for further definition on Priced and Unpriced, and formulas used to calculate value. `],
    'obsoletes': ["This overview summarises the number of lines and value of unique ‘Common’ materials.   \n",
    "Common materials are those materials that have been extended to one or more plants. \n" ,
    "An example of a Common material is material number 5800136091.  This material is extended to plants 100A, B, C, D, E, F, G, H, I and 150A. \n" ,
    "Material number 5800136091 is also a potential duplicate with two other materials.   \n" ,
    "Common materials may also be Potential Duplicate materials and Potential Duplicate materials may also be Common, i.e., they are not mutually exclusive. \n" ,
    `See ${showOnDashboard} Overview box for further definition on Priced and Unpriced, and formulas used to calculate and estimate value.  `],
    'no-bom': ["This overview summarises the number of lines and values of unique materials not assigned to BOMs.   \n",
    `See ${showOnDashboard} Overview box for further definition on Priced and Unpriced, and formulas used to calculate value.  `],
    duplicates: ["This overview summarises the number of lines and value of unique ‘potential duplicate’ materials.   \n",
    "Potential Duplicate materials are those materials identified as being potentially the same as another material based on material attributes that look like manufacturer or supplier part numbers. \n",
    "Potential Duplicate materials are listed in the Commonality Report generated from the SIMS platform.   \n",
    "The Commonality Report shows potential duplicates that have not yet been processed for deduplication. \n" ,
    "The first step in the deduplication process is to verify if a material is a duplicate or not. \n",
    "The action of validating or rejecting a potential duplicate has the effect of removing it from future commonality reports. \n",
    `See ${showOnDashboard} Overview box for further definition on Priced and Unpriced, and formulae used to calculate value.  `],
    'slow-moving': ["This overview summarises the number of lines and values of unique ‘slow-moving’ materials.   \n",
    "Slow-moving materials are those materials with 1 goods issue movement in past 5 years, or stock register <5 years without goods issue movement\n",
    "High Turnover materials are those materials with 3 or more goods issues every year in the past four years. \n",
    "Low Turnover materials are those materials with <3 goods issues per year and more than one goods issue in 4 years. \n",
    "Non-moving materials are those materials with no movement in last four years (also includes materials that had at least one goods issue four or more years ago but no goods issues in last four years). \n",
    `See ${showOnDashboard} Overview box for further definition on Priced and Unpriced, and formulas used to calculate value.  `]
}

function InfoCatModal({cat, isModalVisible, setModalVisible}) {

    const getCatDescr = () => {
        return dict[cat] || []
    }

    return <ModalWindow title={'Category Guide'}
                        isModalVisible={isModalVisible}
                        onOk={() => setModalVisible(false)}
                        scrollVisible={false}
                        onCancel={() => setModalVisible(false)}>
        <ul>
        {getCatDescr().map(v => <li>{v}</li>)}
        </ul>
    </ModalWindow>
}


class DashboardPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filterMode: true,
            loading: false,
            pagination: {},
            selected: '',
            isModalVisible: false
        }
    }


    componentDidMount() {
        const _id = this.props.location.pathname.split('/')[2]
        const panels = ['full', 'duplicates', 'obsoletes', 'insurance', 'slow-moving', 'no-bom']
        for (const element of panels) {
            this.props.getOverviewInfo(element,_id)
        }



    }

    componentDidUpdate(prevProps) {
        if (prevProps.selecmtedTitle !== this.props.selectedTitle) {
                this.props.getFilterValues(this.state.title, this.props.selected.coluns)
        }

        if (prevProps.selectedTitle !== this.props.selectedTitle) {
            this.setState({filterMode: true})
        }
    }

    handleClick(action, title='') {
        this.setState({title: title, filterMode: false})
        action("full", {current: 1, pageSize: 50})
    }

    getType = () => {
        switch (this.props.selectedTitle) {
            case 'Duplicates':
                return 'duplicate'
            case 'Obsoletes':
                return 'obsoletes'
            case 'Insurance Items':
                return 'insurance'
            case 'No BOM Items':
                return 'no-bom'
            case 'Slow Moving Items':
                return 'slow-moving'
            case 'Client Inventory Info':
                return 'full'

            default:
                return this.props.selectedTitle
        }
    }

    search = (query) => {
        if (query) {
            this.props.searchByMaterial(query, this.getType())
        }
        else {
            this.props.getFullItems()
        }
    }

    render() {

        const project_id = this.props.location.pathname.split('/')[2]

        const search = (query) => {
            if (query) {
                this.props.searchByMaterial(query, "full")
            }
            else {
                this.props.getSelectedExamples("full", {current: 1, pageSize: 50})
            }
        }

        const _export = () => {
            message.info('Start exporting the category items')
            this.setState({loading: true})
            const project_id = window.location.pathname.split('/')[2]
            const config = Auth.createConfig();
            config['responseType'] = 'blob';
            axios.get(`/projects/${project_id}/export/${"full"}`, config)
                .then(json => {
                    downloadExcel(json.data, `${"full"} items.xlsx`)
                }).catch(error => {
                console.log(error)
            }).finally(() => this.setState({loading: false}))
        }

        const handleChange = (pagination, filters, sorters) => {
            this.setState({pagination: pagination})
            this.props.getSelectedExamples("full", pagination, filters, sorters)
        }

        return (
            <Layout>
                <Content>
                    <InfoCatModal cat={this.state.selected} isModalVisible={this.state.isModalVisible} setModalVisible={(value) => this.setState({isModalVisible: value})} />
                    <div className='text'>
                        <Row>
                            <Col span={7} />
                            <Col span={10}>
                                <InfoPanel selected={this.props.selectedTitle}
                                           showExpl={true} openModal={() => {
                                               this.setState({isModalVisible: true, selected: 'full'})
                                           }}
                                           title={<div onClick={() => this.props.getSelectedExamples("full", {current: 1, pageSize: 50})}>
                                               <div>{showOnDashboard} Overview</div>
                                           </div>
                                }
                                           info={this.props.inventoryInfo}/>
                            </Col>
                            <Col span={7} />
                        </Row>
                        <Row style={{padding: 10}}>
                        <Divider type='horizontal' orientation='center' style={{opacity: 0.5, backgroundColor: properties.kbrColors.pantone301Blue}} />
                        </Row>
                        <Row>
                            <Col span={8} >
                                <InfoPanel selected={this.props.selectedTitle}
                                           showExpl={true}
                                           openModal={() => {
                                               this.setState({isModalVisible: true, selected: 'duplicates'})
                                           }}
                                           searched={this.props.selected.data && this.props.selected.data.length === 1 && this.props.selected.data[0]['Duplicate'] === 'YES'}
                                           title={<div onClick={() => openHalfLink('commonality', project_id)}>Potential Duplicates</div>}
                                           info={this.props.duplicatesInfo}/>
                            </Col>


                            <Col span={8} >
                                <InfoPanel title={<div onClick={() => openHalfLink('insurance', project_id)}>Insurance Spares</div>}
                                           selected={this.props.selectedTitle}
                                           showExpl={true}
                                           openModal={() => {
                                               this.setState({isModalVisible: true, selected: 'insurance'})
                                           }}
                                           searched={this.props.selected.data && this.props.selected.data.length === 1 && this.props.selected.data[0]['Insurance'] === 'YES'}
                                           info={this.props.insuranceInfo}/>
                            </Col>

                            <Col span={8} >
                                <InfoPanel title={<div onClick={() => openHalfLink('no-bom', project_id)}>No BOM Items</div>}
                                           showExpl={true}
                                           openModal={() => {
                                               this.setState({isModalVisible: true, selected: 'no-bom'})
                                           }}
                                           searched={this.props.selected.data && this.props.selected.data.length === 1 && this.props.selected.data[0]['EQ BOM Count'] === 0}
                                           selected={this.props.selectedTitle}
                                           info={this.props.noBOMInfo}/>
                            </Col>
                        </Row>
                    <Row>
                        <Col span={12} >
                            <InfoPanel title={<div onClick={() => openHalfLink('obsoletes', project_id)}>{show_obsoletes ? 'Obsoletes' :'Common Stock'}</div>}
                                       selected={this.props.selectedTitle}
                                       showExpl={!show_obsoletes}
                                       openModal={() => {
                                           this.setState({isModalVisible: true, selected: 'obsoletes'})
                                       }}
                                       searched={this.props.selected.data && this.props.selected.data.length === 1 && this.props.selected.data[0]['PotOb'] === 'YES'}
                                       info={this.props.obsoleteInfo}/>
                        </Col>
                        <Col span={12} >
                            <InfoPanel title={<div onClick={() => openHalfLink('slow-moving', project_id)}>Slow Moving Items</div>}
                                       searched={this.props.selected.data && this.props.selected.data.length === 1 && this.props.selected.data[0]['Type'] === 'Slow Moving'}
                                       selected={this.props.selectedTitle}
                                       showExpl={true}
                                       openModal={() => {
                                           this.setState({isModalVisible: true, selected: 'slow-moving'})
                                       }}
                                       info={this.props.slowMovingInfo}/>
                        </Col>
                    </Row>
                    </div>

                    {this.props.selected && this.props.selected.data &&  <Layout className="site-layout" style={{minHeight: 555}}><div style={{margin: 10, maxHeight: 300}}>
                        <Row>
                            <TablePanel pagination={this.state.pagination} total={this.props.total} export={_export} search={search} data={this.props.selected} handleChange={handleChange}/>
                        </Row>
                    </div></Layout>}
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        inventoryInfo: state.inventoryInfo,
        duplicatesInfo: state.duplicatesInfo,
        obsoleteInfo: state.obsoleteInfo,
        insuranceInfo: state.insuranceInfo,
        slowMovingInfo: state.slowMovingInfo,
        noBOMInfo: state.noBOMInfo,
        selected: state.selected,
        total: state.total,
        selectedTitle: state.selectedTitle,
        lastCategorySelected: state.lastCategorySelected,
        filterValues: state.filterValues
    }
}

export default connect(mapStateToProps, {
    getOverviewInfo,
    getFilterValues,
    filter,
    getSelectedExamples,
    getFullItems,
    getDuplicatesItems,
    getObsoleteItems,
    getInsuranceItems,
    getSlowMovingItems,
    getNoBOMItems,
    searchByMaterial
})(DashboardPanel)
