import React, {Component} from "react";
import {Button, Col, Descriptions, Menu, Row, Spin, Statistic, Switch} from "antd";
import {DataCard} from "../../components/helpers/cards/Card";
import api from "../../api";
import {createScopeLink, getProjectId} from "../../helpers";
import {ModalWindow} from "../../components/helpers/modals/ModalWindow";
import DescriptionsItem from "antd/es/descriptions/Item";
import {projectCurrency, properties} from "../../properties";
import {VerticalChart} from "../../components/helpers/VerticalChart";
import {Graph} from "../../components/helpers/Graph";
import {CustomTabs} from "../../components/helpers/CustomTabs";
import {DoughnutGraph} from "../../components/helpers/DoughnutGraph";

export class DashboardV2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            selectedCategory: {},
            selectedKeys: [],
            projectId: getProjectId(this.props.location)
        }
    }

    componentDidMount() {
        this.getCategories()
    }

    getCategories = () => {
        api.get(`/projects/${this.state.projectId}/dashboard/categories`)
            .then(json => {
                    this.setState({
                        categories: json.data,
                    })
                    let path = this.props.location.pathname.split('/')
                    if (path.length > 4 && path[path.length - 1] !== '') {
                        let categoryId = path[path.length - 1]
                        let category = this.findCategoryById(categoryId, json.data)
                        this.setState({
                            selectedCategory: category,
                            selectedKeys: category.category
                        },)
                    } else {
                        this.setState({
                            selectedCategory: json.data[0],
                            selectedKeys: [json.data[0].category]
                        })
                    }
                }
            )
    }

    parseCategories = _list => {
        if (!_list) {
            return []
        }
        return _list.map(_item => {

            let children;
            if (_item['includes'] && _item['includes'].length) {
                children = this.parseCategories(_item['includes'])
            }

            return {
                label: _item['category'],
                key: _item['category'],
                onTitleClick: () => this.openMainCategory([_item['category']]),
                children: children
            }
        })
    }


    createMenu = () => {
        return this.parseCategories(this.state.categories)
    }

    findCategory = (key, _list) => {
        let needed;
        for (let _category of _list) {
            if (_category.category === key) {
                return _category

            }
            if (_category.includes) {
                needed = this.findCategory(key, _category.includes)
                if (needed) {
                    return needed
                }
            }

        }
        return null
    }


    findCategoryById = (_id, _list) => {
        let needed;
        for (let _category of _list) {
            if (_category._id === _id) {
                return _category

            }
            if (_category.includes) {
                needed = this.findCategoryById(_id, _category.includes)
                if (needed) {
                    return needed
                }
            }

        }
        return null
    }

    handleOpen = (item) => {
        this.setState({selectedCategory: this.findCategory(item.key, this.state.categories), selectedKeys: item.key},)
    }

    openMainCategory = open => {
        if (open.length === 1) {
            this.handleOpen({key: open[0]})
        }
    }


    render() {
        return <div><Row>
            <Col style={{padding: 5}} span={6}>

                <DataCard style={{height: 'calc(100vh - 75px)', maxHeight: 'calc(100vh - 75px)', overflowY: 'auto'}}>
                    <Menu style={{maxHeight: 'calc(100vh - 100px)',}} items={this.createMenu()}
                          selectedKeys={this.state.selectedKeys}
                          mode={"inline"} onClick={this.handleOpen}/>
                </DataCard>

            </Col>
            <Col style={{padding: 5}} span={18}>
                <DashboardPresentation selectedCategory={this.state.selectedCategory} projectId={this.state.projectId}/>
            </Col>

        </Row></div>
    }

}

export class DashboardPresentation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            scopeInfo: {},
            isModalVisible: false,
            scopeDashboardOverview: {},
            categoryOverview: {},
            graphMode: 'statistics',
            activeKey: 'overview',
            history: []
        }
    }

    getCategoryOverview = () => {
        this.setState({categoryOverview: {}, loading: true})
        api.get(`/projects/${this.props.projectId}/dashboard/category/overview?scope_id=${this.props.selectedCategory.scope_id}`)
            .then(json => this.setState({
                categoryOverview: json.data
            }))
            .catch(error => this.setState({categoryOverview: {}}))
            .finally(() => this.setState({loading: false}))
    }

    getCategoryHistory = () => {
        this.setState({history: [], historyLoading: true})
        api.get(`/projects/${this.props.projectId}/dashboard/category/history?category_id=${this.props.selectedCategory._id}&category_name=${this.props.selectedCategory.category}`)
            .then(json => this.setState({
                history: json.data
            }))
            .catch(error => this.setState({history: []}))
            .finally(() => this.setState({historyLoading: false}))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedCategory !== this.props.selectedCategory) {
            this.setState({
                graphMode: 'statistics',
                activeKey: 'overview',
            })
            if (!this.props.selectedCategory.results) {
                this.getCategoryOverview()
            } else {
                this.setState({
                    categoryOverview: this.props.selectedCategory.results,
                    loading: false
                })
            }

            this.getScopeOverview()
            this.getCategoryHistory()
        }
    }

    createTitle = () => {
        return <div>{this.props.selectedCategory.category} <Button onClick={() => this.setState({isModalVisible: true})}
                                                                   style={{float: 'right'}}>?</Button></div>
    }

    getScopeOverview = () => {
        api.get(`/projects/${this.props.projectId}/scope/${this.props.selectedCategory.scope_id}`)
            .then(json => this.setState({scopeInfo: json.data}))
            .catch(error => this.setState({scopeInfo: {}}))
    }

    getPrettyNumber = (_number, getHtml = true) => {
        if (_number / 1000000 > 1) {

            if (getHtml) {
                return <Statistic title={`Total Value ${projectCurrency}`} value={Math.round(_number / 10000) / 100}
                                  suffix={'m'}/>
            } else {
                return `${Math.round(_number / 10000) / 100} m`
            }


        } else if (_number / 1000 > 1) {
            if (getHtml) {
                return <Statistic title={`Total Value ${projectCurrency}`} value={Math.round(_number / 10) / 100}
                                  suffix={'K'}/>
            } else {
                return `${Math.round(_number / 10) / 100} K`
            }


        }
    }

    getPercentage = (_value, _total) => {
        return Math.round((_value / _total) * 100)
    }

    getGraphData = () => {
        const stat = this.state.categoryOverview
        if (this.state.graphMode === 'statistics') {
            return [
                this.getPercentage(stat.total, stat.total),
                this.getPercentage(stat.unique, stat.total),
                this.getPercentage(stat.stocked, stat.total),
                this.getPercentage(stat.priced, stat.total)
            ]
        } else if (this.state.graphMode === 'history') {
            let chartData = this.state.history.map(_record => {
                return {
                    ..._record['results'],
                    'date': _record['date']
                }
            })
            return chartData
        }
    }

    switchMode = (checked) => {
        if (!checked) {
            this.setState({graphMode: 'statistics'})
        } else {
            this.setState({graphMode: 'history'})
        }
    }

    getTabs = () => {
        const chartColors = [
            properties.kbrColors.green,
            properties.kbrColors.acajouMarron,
            properties.kbrColors.vividCeruleanBlue,
            properties.kbrColors.yellow,

        ]

        const labels = ['Total', 'Unique', 'Stocked', 'Priced']
        const historyLabels = ['total', 'unique', 'stocked', 'priced']

        let tabs = [{
            name: 'Overview',
            key: 'overview',
            children: <div>
                <div>
                    <Switch unCheckedChildren={'Statistics'}
                            checked={this.state.graphMode === 'history'}
                            checkedChildren={'History'} onChange={this.switchMode}/>
                </div>

                {this.state.graphMode === 'statistics' && <div>
                    <Spin spinning={this.state.loading}>
                        <VerticalChart colors={chartColors} labels={labels} source={this.getGraphData()}
                                       height={'calc(100vh - 575px)'}/>
                    </Spin>
                </div>}

                {this.state.graphMode === 'history' && <div>
                    <Spin spinning={this.state.historyLoading}>
                        <Graph colors={chartColors} labels={historyLabels} legendFields={labels}
                               data={this.getGraphData()}
                               height={'calc(100vh - 575px)'}/>
                    </Spin>
                </div>}
            </div>
        }]

        if (Object.keys(this.props.selectedCategory).includes('ratio_data')) {

            const labels = {
                priced: 'Priced',
                total: 'Total',
                unique: 'Unique',
                stocked: 'Stocked',
                total_value: 'Total Value'
            }

            let colors = ['#fd8a8a', '#97ecf1', '#bdb2ff', '#fad1fa', '#c9e4de', '#c9e4de']

            const ratio = this.props.selectedCategory['ratio_data']
            tabs.push({
                name: 'Ratio',
                key: 'ratio',
                children: <Row style={{maxHeight: 'calc(100vh - 540px)', overflowY: 'auto'}}>{Object.keys(ratio).map(v => {
                    let values = []
                    let scopeLabels = []

                    for (let key in ratio[v]) {
                        if (key !== 'overview') {
                            values.push(ratio[v][key])
                            scopeLabels.push(key)
                        }
                    }

                    return <Col span={12} style={{padding: 10}}>
                        <Row>
                            <Col span={10}>
                                <div><b>{labels[v]}:</b></div>
                                <div>{scopeLabels.map(_scope => _scope && <div>
                                    {_scope} ({Math.round(ratio[v][_scope] / ratio[v]['overview'] *100)} %): {v === 'total_value' ? this.getPrettyNumber(ratio[v][_scope], false) : ratio[v][_scope]}
                                </div>)}
                                </div>
                            </Col>
                            <Col span={14}>
                                <DoughnutGraph data={values}
                                               labels={scopeLabels}
                                               legendLabels={scopeLabels}
                                               full={false}
                                               height={100}
                                               colors={colors}
                                               showLegend={false}/>
                            </Col>
                        </Row>
                    </Col>
                })}</Row>
            })

        }


        return tabs
    }

    render() {


        const scope = this.state.scopeInfo
        const statistic = this.state.categoryOverview

        return <DataCard style={{height: 'calc(100vh - 75px)'}} title={this.createTitle()}>
            <InfoCategoryModal isModalVisible={this.state.isModalVisible}
                               text={this.props.selectedCategory.category_description}
                               setModalVisible={state => this.setState({isModalVisible: state})}/>
            <DataCard style={{margin: 5}}>
                <Row>
                    <Col span={16} style={{padding: 5}}>
                        <Spin spinning={this.state.loading}>
                            <Row>
                                <Col span={12}>
                                    <Statistic title={'Total Items'} value={statistic.total}/>
                                </Col>
                                <Col span={12}>
                                    {this.getPrettyNumber(statistic.total_value)}
                                </Col>
                            </Row>

                            <Row>
                                <Col span={8}>
                                    <Statistic title={'Unique Items'} value={statistic.unique}/>
                                </Col>
                                <Col span={8}>
                                    <Statistic title={'Stocked Items'} value={statistic.stocked}/>
                                </Col>
                                <Col span={8}>
                                    <Statistic title={'Priced Items'} value={statistic.priced}/>
                                </Col>
                            </Row>
                            <div style={{height: 11}}></div>

                        </Spin>

                    </Col>
                    <Col span={8} style={{padding: 5}}>

                        <Descriptions>
                            <DescriptionsItem labelStyle={{fontWeight: 'bold'}} span={3}
                                              label={"Scope name"}>{scope.name}</DescriptionsItem>
                            <DescriptionsItem labelStyle={{fontWeight: 'bold'}} span={3}
                                              label={"Scope description"}>{scope.description}</DescriptionsItem>
                            {scope._id &&
                                <DescriptionsItem labelStyle={{fontWeight: 'bold'}} span={3} label={"Open scope"}>
                                    <a href={createScopeLink(this.props.projectId, scope._id)}>Open</a>
                                </DescriptionsItem>}
                        </Descriptions>

                    </Col>
                </Row>
            </DataCard>

            <DataCard style={{padding: 20, margin: 5}}>
                <CustomTabs activeKey={this.state.activeKey}
                            defaultKey={this.state.activeKey}
                            tabsData={this.getTabs()}
                            onChange={activeKey => this.setState({activeKey: activeKey})}/>

            </DataCard>

        </DataCard>
    }

}

function InfoCategoryModal({text, isModalVisible, setModalVisible}) {

    return <ModalWindow title={'Category Guide'}
                        isModalVisible={isModalVisible}
                        onOk={() => setModalVisible(false)}
                        scrollVisible={false}
                        onCancel={() => setModalVisible(false)}>
        <ul>
            {text}
        </ul>
    </ModalWindow>
}
