import React, {useEffect, useState} from 'react'
import {DataCard} from "../components/helpers/cards/Card";
import {checkAllPermissions, checkAnyOfPermissions, getURLParam, labelMapper, sumByField} from "../helpers";
import api from "../api";
import {Col, Row, Descriptions, Statistic} from "antd";
import {MaterialBlock} from "../components/helpers/cards/MaterialBlock";
import {DefaultTable, InsTable} from "../components/settings/InsuranceSettings";
import {EditOutlined} from "@ant-design/icons";
import {StandardModalForm} from "../components/helpers/modals/StandardModalForm";
import {ModalWindow} from "../components/helpers/modals/ModalWindow";
import {InsuranceGraph} from "../components/helpers/graphs/InsuranceGraph";

export function InsuranceMaterialPage ({}) {

    const [item, setItem] = useState({
        'equipment': [],
    })
    const [defaultTable, setDefaultTable] = useState([])
    const [tableModalVisible, setTableModalVisible] = useState(false)

    const [graphModalVisible, setGraphModalVisible] = useState(false);
    const [projectId, setProjectId] = useState(window.location.pathname.split('/')[2])
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [material, setMaterial] = useState('')
    const [insTable, setInsTable] = useState([])
    const [best, setBest] = useState(null)
    const [rows, setRows] = useState([])
    const [canEdit, setCanEdit] = useState(false)
    const [editedRow, setEditedRow] = useState({})
    const [initialValues, setInitialValues] = useState({})

    useEffect(() => {
        setMaterial(getURLParam('material'))
    }, [])

    const checkRights = permissions => {
        checkAnyOfPermissions(permissions)
            .then(() => setCanEdit(true))
            .catch(() => setCanEdit(false))
    }

    const get_material_info = () => {
        api.get(`/projects/${projectId}/insurance-materials/info?query=${material}`)
            .then(json => {
                const data = json.data
                setItem({...item, ...data})
                if (data.length > 0) {
                    getRows(data)
                }
            })
    }

    const get_material_table = () => {
        api.get(`/projects/${projectId}/insurance-materials/table?query=${material}`)
            .then(json => {
                const data = json.data
                setBest(data.best_option)
                setInsTable(data.table)
            })
    }

    const get_default_info = () => {
        api.get(`/projects/${projectId}/insurance_defaults`)
            .then(json => setInitialValues(json.data.constants))
    }

    const getDefaultTable = () => {
        api.get(`/projects/${projectId}/insurance_table_defaults`)
            .then(json => {
                setDefaultTable(json.data)
            })
    }

    const getRows = (data) => {
        let _rows = [...defaultTable]
        if (Object.keys(data).includes('tableData')) {
            _rows = data.tableData
        }
        setRows([..._rows])
    }

    useEffect(() => {
        getRows({...item})
    }, [defaultTable]);

    const getCostLoss = rows => {
        let sum = 0;
        for (let row of rows) {
            sum = sum + row['constants']['costPerUnit'] * (row['constants']['loss'] / 100)
        }

        return sum;
    }

    const getDataSource = () => {
        const tableRows = [...rows]
        tableRows.push({
                _id: 'Total',
                constants: {
                    production_output: 'Total',
                    unitsPerDay: ' ',
                    costPerUnit: sumByField(rows, ['constants','costPerUnit']),
                    costLoss: getCostLoss(rows),
                    loss: ''
                },
                isLast: true

            })
        return [...tableRows]
    }

    useEffect(() => {
        if (material) {
            get_default_info()
            getDefaultTable()

            get_material_info()
            get_material_table()

            checkRights(['senior-user', 'approve-user', 'insurance-admin'])
        }
    }, [material])



    const handleDelete = row => {
        let _rows = [...rows];
        _rows = _rows.filter(x => x._id !== row._id)
        updateValues({tableData: _rows})
    }

    const handleEdit = row => {
        setEditedRow(row)
        setTableModalVisible(true)
    }

    const closeTableModal = () => {
        setTableModalVisible(false)
        setEditedRow({})
    }

    const createNewRow = row => {
        return {
            _id: new Date().getMilliseconds(),
            constants: {...row}
        }
    }

    const saveTableRow = row => {
        const _rows = [...rows]
        if (Object.keys(editedRow).length === 0) {
            _rows.push(createNewRow(
                row
            ))
        } else {
            let oldRow = _rows.find(x => x._id === editedRow._id)
            oldRow.constants = {...oldRow.constants, ...row}
        }
        updateValues({tableData: _rows.filter(x=> x._id !== 'Total')})
        setTableModalVisible(false)
    }

    const updateValues = values => {
        api.post(`/projects/${projectId}/insurance-materials/update?query=${material}`, values)
            .then(() => {
                get_material_info()
                get_material_table()
                setEditedRow({})
            })
    }

    const closeEditModal = () => {
        setEditModalVisible(false)
    }

    const saveEditValues = values => {
        updateValues(values)
        setEditModalVisible(false)
    }

    const getFormFields = () => [
        {label: labelMapper('demand_quantity'), name: 'demand_quantity', _type: 'number', message: 'Error', required: true},
        {label:labelMapper('demand_interval'), name: 'demand_interval', _type: 'number', message: 'Error', required: true},
        {label: labelMapper('shell_life'), name: 'shell_life', _type: 'number', message: 'Error', required: true},
        {label: labelMapper('installed'), name: 'installed', _type: 'number', message: 'Error', required: true},
        {label: labelMapper('required'), name: 'required', _type: 'number', message: 'Error', required: true},
        {label: labelMapper('running_hours'), name: 'running_hours', _type: 'number', message: 'Error', required: true},
        {label: labelMapper('capital_cost'), name: 'capital_cost', _type: 'number', message: 'Error', required: true},
        {label: labelMapper('holding'), name: 'holding', _type: 'number', message: 'Error', required: true},
    ]

    const getTableFormFields = () => [
        {label: labelMapper('production_output'), name: 'production_output', _type: 'string', message: 'Error', required: true},
        {label: labelMapper('unitsPerDay'), name: 'unitsPerDay', _type: 'number', message: 'Error', required: true},
        {label: labelMapper('costPerUnit'), name: 'costPerUnit', _type: 'number', message: 'Error', required: true},
        {label: labelMapper('loss'), name: 'loss', _type: 'number', message: 'Error', required: true},
    ]

    const getAddColumns = () => [
        {title: <b>{labelMapper('unitsPerDay')}</b>, dataSource: 'unitsPerDay', key: 'unitsPerDay', render: (dataSource, item) => item.constants.unitsPerDay || 0},
        {title: <b>{labelMapper('costPerUnit')}</b>, dataSource: 'costPerUnit', key: 'costPerDay', render: (dataSource, item) => <Statistic valueStyle={{fontSize: 12}} value={item.constants.costPerUnit || 0} />},
        {title: <b>{labelMapper('loss')}</b>, dataSource: 'loss', key: 'loss', render: (dataSource, item) => item.constants.loss},
        {title: <b>Cost Loss</b>, dataIndex: 'cost_loss', key: 'cost_loss', render: (dataSource, item) => <Statistic valueStyle={{fontSize: 12}} value={item.constants.costLoss ? Math.round(item.constants.costLoss * 10**4) / 10**4 : Math.round((item.constants.costPerUnit || 0) * (item.constants.loss / 100) * 10**4) / 10**4 || ''} />},
    ]

    const header = ['shell_life', 'demand_quantity', 'demand_interval', 'price',
        'installed', 'required', 'equipment','criticality', 'ratio', 'running_hours', 'capital_cost', 'holding']

    const beautifyValue = (key, value) => {
        if (key === 'Unit$') {
            return <Statistic valueStyle={{fontSize: 12}} value={value} />
        } else if (key === 'equipment') {
            return value.join(', ')
        } else if (key in initialValues) {
            return value || initialValues[key]
        }
        return value
    }

    return <div style={{padding: 5}}>
        <Row style={{height: '25%', maxHeight: '25%', scroll: 'auto'}}>
            <Col span={12} style={{padding: 5 }}>
                <DataCard style={{height: '100%'}} title={
                    <Row>
                        <b style={{marginRight: 5}}>Material:</b>
                        <MaterialBlock material={item.material} children={<div>{item.material} - {item['description']}</div>}/>
                        {canEdit && <EditOutlined style={{marginLeft: 5, marginTop: 5}}
                                       onClick={() => setEditModalVisible(true)}/>}

                    </Row>
                }>
                    <Descriptions bordered size={'small'}>
                        {header.map(_key => <Descriptions.Item span={2} label={<b>{labelMapper(_key)}</b>}>
                            {beautifyValue(_key, item[_key])}
                        </Descriptions.Item>)}
                    </Descriptions>
                </DataCard>
            </Col>
            <Col span={12} style={{padding: 5}}>
                <DataCard style={{height: '100%'}}>
                    <div className='float-right beautiful-link'
                                                onClick={() => setTableModalVisible(true)}>Add new row</div>
                    <DefaultTable addColumns={getAddColumns()} dataSource={getDataSource()} onEdit={handleEdit} isAdmin={canEdit} onDelete={handleDelete} />
                </DataCard>
            </Col>
        </Row>
        <Row>
            <Col span={24} style={{padding: 5, height: '25%', maxHeight: '25%'}}>
                <DataCard style={{height: '100%'}}>
                    <div style={{float: 'right'}} className={'beautiful-link'} onClick={() => setGraphModalVisible(true)}>Open Graph</div>
                    <InsTable best={best} dataSource={insTable} />
                </DataCard>
            </Col>
        </Row>
        {graphModalVisible && <ModalWindow isModalVisible={graphModalVisible}
                                           onOk={() => setGraphModalVisible(false)}
                                           onCancel={() => setGraphModalVisible(false)}
                                           title={'Stock Out and Holding Cost Comparison\n'}>
            <InsuranceGraph graphData={insTable} />
        </ModalWindow>}
        {editModalVisible && <StandardModalForm fields={getFormFields()} isModalVisible={editModalVisible} closeModal={closeEditModal}
                            initialValues={{...initialValues, ...item}} save={saveEditValues}/>}
        {tableModalVisible && <StandardModalForm fields={getTableFormFields()} isModalVisible={tableModalVisible}
                            closeModal={closeTableModal} initialValues={{...editedRow.constants}} save={saveTableRow}/>}

    </div>

}
