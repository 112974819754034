import React from "react";
import '../helpers.css'
import {
    Layout,
    Typography,
    Space,
} from "antd";
import '../../Dashboard.css'
import {
    About,
    ContactUsLink, ContactUsModal,
    CurrentUserAvatar,
    GuideButton,
    HomeLink,
    LogOut,
    OtherResources
} from "@digatex/digatex-ui-lib";
import api from "../../api";
import {properties} from "../../properties";


function sendEmail(formData) {
    return api.post(`/email/contact`, formData);
}

class DHeader extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showContactForm: false,
            triedToSubmit: false,
            submitInProgress: false,
            project: {}
        }

        this.formRef = React.createRef();
    }

    componentDidMount() {
        const projectId = document.location.pathname.split('/')[2];
        api.get(`/projects/${projectId}/info`).then(json => this.setState({project: json.data}))
    }

    render() {
        return (
            <div>
                <Layout.Header style={{padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    {!this.props.isLanding ? <HomeLink/> : <HomeLink title={<div style={{width: 50}}></div>} />}
                    {this.props.isLanding && <Typography.Title level={3} style={{color: 'white', paddingLeft: 32, paddingTop: 7.25, marginLeft: 'auto'}}>
                        {properties.landingHeaderName}
                    </Typography.Title>
                    }
                    {this.state.project.project_name && <Typography.Title level={3}
                                       style={{color: 'white', paddingLeft: 32, paddingTop: 7.25, marginLeft: 'auto'}}>
                        Project: {this.state.project.project_name}
                    </Typography.Title>}

                    <div style={{marginLeft: 'auto'}} >
                        <Space size={"middle"}>
                            <About/>
                            <OtherResources/>
                            {!this.props.isLanding && <GuideButton module="workbench"/>
                            }                            <ContactUsLink show={() => this.setState({showContactForm: true})}/>
                            <LogOut />
                            <CurrentUserAvatar/>
                        </Space>
                    </div>

                </Layout.Header>
                <ContactUsModal show={this.state.showContactForm}
                                sendEmail={sendEmail}
                                close={() => this.setState({showContactForm: false})}/>
            </div>
        );
    }
}

export const DashboardHeader = DHeader
