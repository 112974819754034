import React from 'react';
import {LeadTimeReport} from "./LeadTimeReport";
import {CustomTabs} from "../helpers/CustomTabs";
import {MMSTAReport} from "./MMSTAReport";


export function Reports() {

    const getTabs = () => [{
        name: 'Lead Time Report',
        key: 'lead-report',
        children: <LeadTimeReport/>
    },{
        name: 'MMSTA Report',
        key: 'mmsta-report',
        children: <MMSTAReport/>
    }]


    return <div style={{margin: 10}}>
        <CustomTabs tabsData={getTabs()} />
    </div>
}
