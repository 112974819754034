import React from "react";
import {Select} from "antd";
import {SimpleForecasting} from "./SimpleForecasting";
import {CrostonForecast} from "../helpers/modals/CorstonModal";
import {DataCard} from "../helpers/cards/Card";

export function Forecasts({
                              projectId, material, itemInfo,
                              forecastMin, forecastMax,
                              onRecAdd, change,currentRec, constants,
                              handleSelect, canEdit = true,
                              forecastMethod = 'naive'
                          }) {

    const getForecastOptions = () => [{
        'value': 'naive',
        'label': `Statistical Forecasting (naive)`
    }]

    return <DataCard>
        <Select options={getForecastOptions()}
                onSelect={handleSelect}
                style={{width: 250}} value={forecastMethod}/>

        {forecastMethod === 'naive' && <SimpleForecasting projectId={projectId}
                                                          canEdit={canEdit}
                                                          change={change}
                                                          constants={constants}
                                                          currentRec={currentRec}
                                                          itemInfo={itemInfo} forecastMin={forecastMin}
                                                          onRecAdd={onRecAdd}
                                                          material={material} forecastMax={forecastMax}/>}

        {forecastMethod === 'croston' && <CrostonForecast projectId={projectId}
                                                          show={true}
                                                          material={material}/>}
    </DataCard>
}
