import {Form, InputNumber, Select} from "antd";
import React, {useEffect} from "react";
import {ModalWindow} from "./ModalWindow";
import {labelMapper} from "../../../helpers";


export function ParamsModal({save, isModalVisible, closeModal, initialValues, scopes = []}) {

    const [form] = Form.useForm();

    const handleCancel = () => {
        closeModal();
    };

    const onFinish = (values) => {
        save({...values})
    };

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue(initialValues)
    }, [isModalVisible])

    const getOptions = scopes.map((scope, i) => {

        return {
            label: scope.name,
            value: scope._id,
        }

    })

    return <ModalWindow width={900} title={`Editing Default Parameters`}
                        isModalVisible={isModalVisible}
                        scrollVisible={false}
                        onCancel={handleCancel}
                        modalProps={{
                            okText: "Update",
                            cancelText: "Cancel",
                            okButtonProps: {form: 'materialEditForm', key: 'submit', htmlType: 'submit'}
                        }}>
        <Form
            onFinish={onFinish}
            form={form}
            initialValues={initialValues}
            id="materialEditForm"
        >
            <Form.Item
                label={labelMapper('capital_cost')}
                name="capital_cost"
                rules={[
                    {
                        required: true,
                        message: 'Please input the capital cost!',
                    },
                ]}
            >
                <InputNumber/>
            </Form.Item>
            <Form.Item
                label={labelMapper('holding')}
                name="holding"
                rules={[
                    {
                        required: true,
                        message: 'Please input the holding',
                    },
                ]}
            >
                <InputNumber/>
            </Form.Item>
            <Form.Item
                label={labelMapper('shell_life')}
                name="shell_life"
                rules={[
                    {
                        required: true,
                        message: 'Please input the Shelf Life',
                    },
                ]}
            >
                <InputNumber/>
            </Form.Item>
            <Form.Item
                label={labelMapper('running_hours')}
                name="running_hours"
                rules={[
                    {
                        required: true,
                        message: 'Please input the Running Hours',
                    },
                ]}
            >
                <InputNumber/>
            </Form.Item>

            <Form.Item
                label={'Attached Scope'}
                name="scope"
                rules={[
                    {
                        required: true,
                        message: 'Please input the Scope',
                    },
                ]}
            >
                <Select options={getOptions}/>
            </Form.Item>
        </Form>
    </ModalWindow>

}
