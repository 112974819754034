import React from "react";
import {Doughnut} from "react-chartjs-2";

export function DoughnutGraph({labels, colors, full, showLegend, legendPosition, height, data, legendLabels}) {

    const getDataForGraph = (graphData) => {

        let borders = colors || []
        let useColors = borders.map(v => v + '33')

        if (borders.length === 0) {

            useColors = [
                'rgba(0, 75, 135, 0.2)',
                'rgba(162,199,226, 0.2)',
            ];

            borders = [
                'rgba(0, 75, 135, 1)',
                'rgba(162,199,226, 1)',
            ]
        }

        const extraColors = [
            'rgba(0,120,63, 0.2)',
            'rgba(255,218,0, 0.2)',
        ]

        const extraBorders = [
            'rgba(0,120,63, 1)',
            'rgba(255,218,0, 1)',
        ]
        if (labels.length !== useColors.length) {
            if (useColors.length < labels.length) {
                useColors = [...useColors, ...extraColors]
                borders = [...borders, ...extraBorders]

            }
        }

        return {
            labels: legendLabels.map(v => v + ' (%)'),
            datasets: [
                {
                    data: graphData,
                    backgroundColor: useColors,
                    borderColor: borders,
                    borderWidth: 1,
                },
            ],
        }
    }

    let showFull = full || false;
    let legend = showLegend || false;

    const options = {
        plugins: {
            legend: {
                display: legend,
                position: legendPosition ? legendPosition : "right",
            },
        },
        rotation: showFull ? 0 : 270,
        circumference: showFull ? 360 : 180,
        interaction: false
    };

    return (
        <Doughnut style={{height: height, maxHeight: height}} data={getDataForGraph(data)} options={options}/>
    );

}
