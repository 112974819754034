import React from 'react'
import {DataCard} from "../../helpers/cards/Card";
import api from "../../../api";
import {StandardTable} from "../../helpers/TablePanel";
import {MaterialBlock} from "../../helpers/cards/MaterialBlock";
import {createWorkflowLink, downloadExcel, getProjectId, openLinkInNewTab} from "../../../helpers";
import Search from "antd/es/input/Search";
import * as Auth from "../../../AuthService";
import {DownloadOutlined} from "@ant-design/icons";


export class WorkflowStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            stepsData: [],
            projectId: getProjectId(props.location),
            page: 1,
            search: '',
            options: {},
            filters: {},
            total: 0,
            loading: false
        }
    }

    getTasks = (page = this.state.page, search = this.state.search, filters=this.state.filters) => {
        this.setState({loading: true})
        if (filters !== this.state.filters) {
            page = 1
        }
        api.post(`/tasks/${page}?deleted=true&attachement=false&search=${search}`, filters)
            .then(json => {
                if (json.data.length > 0) {
                    this.setState({
                        stepsData: json.data,
                        page: page,
                        total: (page - 1) * 20 + json.data.length + 1,
                        search: search,
                        filters: filters,
                    })
                } else {
                    this.setState({
                        total: (page - 1) * 20
                    })
                }
            }).finally(() => this.setState({loading: false}))
    }

    getFilterOptions = field => {
        api.get(`/projects/${this.state.projectId}/tasks/filter/options?field_name=${field}`)
            .then(res => {
                this.setState({
                    options: {
                        ...this.state.options, [field]: res.data
                    }
                })

            })
    }

    createFilterOptions = (field) => {
        return (this.state && this.state.options) && this.state.options[field] && this.state.options[field].map(v => {
            return {
                text: v,
                value: v,
            }
        })
    }


    componentDidMount() {
        this.getTasks()
        this.getFilterOptions('description')
        this.getFilterOptions('step_type')
        this.getFilterOptions('status')
        this.getFilterOptions('user')
    }

    columns = () => [{
        title: 'Workflow Name',
        dataIndex: 'description',
        render: (workflow_name, step) => {
            return <div className={'beautiful-link'}
                        onClick={() => openLinkInNewTab(createWorkflowLink(this.state.projectId, step.workflow_id))}>{workflow_name}</div>
        },
        filters: this.createFilterOptions('description')
    }, {
        title: 'Workflow Step',
        dataIndex: 'step',
        render: step_id => step_id + 1
    }, {
        title: 'Step Type',
        dataIndex: 'step_type',
        filters: this.createFilterOptions('step_type')

    }, {
        title: 'Step status',
        dataIndex: 'status',
        filters: this.createFilterOptions('status')

    }, {
        title: 'Step comment',
        dataIndex: 'comment'
    }, {
        title: 'Workflow Object',
        dataIndex: 'object',
        render: (object, step) => <div>
            {step.object_type === 'material' && <MaterialBlock material={object} children={object}/>}
        </div>
    }, {
        title: 'Assigned To',
        dataIndex: 'user',
        width: '30%',
        filters: this.createFilterOptions('user'),
        render: (user, item) => <div>{user} {item.position ? `(${item.position})` : ''}</div>
    },]

    onChange = (pagination, filters) => {
        this.getTasks(pagination.current, this.state.search, filters)
    }

    onSearch = search => {
        this.getTasks(1, search)
    }

    export = () => {
        let data = {
            search: this.state.search,
            filters: this.state.filters,
        }
        const config = Auth.createConfig();
        config['responseType'] = 'blob';
        api.post(`/projects/${this.state.projectId}/tasks/export`, data, config)
            .then(json => {
                downloadExcel(json.data, `Workflow status list.xlsx`)
            })
    }

    render() {
        return (
            <DataCard title={<div>Statuses of the workflows <DownloadOutlined onClick={() => this.export()} className='beautiful-link' /></div>} style={{height: '97%', margin: 10}}>
                <div style={{marginBottom: 10}}>
                    <Search
                        placeholder="Search by workflow object or comment"
                        allowClear
                        enterButton="Search"
                        onSearch={this.onSearch}
                    />
                </div>
                <StandardTable loading={this.state.loading} data={this.state.stepsData} total={this.state.total}
                               currentPage={this.state.page} onChange={this.onChange} pageSize={20}
                               columns={this.columns()} tableSize={'small'} style={{height: '100%'}} scroll={625}/>
            </DataCard>
        );
    }

}
