import React, {Component, useEffect, useState} from "react";
import {checkAnyOfPermissions, getProjectId} from "../../helpers";
import api from "../../api";
import {DataCard} from "../../components/helpers/cards/Card";
import {StandardTable} from "../../components/helpers/TablePanel";
import {Button, Form, Input, message, Popconfirm, Select, Space, Spin} from "antd";
import {DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {ModalWindow} from "../../components/helpers/modals/ModalWindow";
import {useForm} from "antd/es/form/Form";
import {StandardForm} from "../../components/helpers/forms/StandardForm";

function EditDashboardCategory({mode = 'add', isModalVisible, onOk, onCancel, scopes, initialValues = {}}) {

    const [form] = useForm()
    const [attach, setAttach] = useState('')

    const getValuesFields = () => [
        {label: 'Category Name', name: 'category', _type: 'text', required: true},
        {label: 'Category Description', name: 'category_description', _type: 'long_text',},
    ]

    useEffect(() => {
        form.resetFields()
        if (isModalVisible) {
            form.setFieldsValue(initialValues)
            if (initialValues.scope_id) {
                setAttach('scope')
            }
        }
    }, [isModalVisible]);

    useEffect(() => {
        if (mode === 'add') {
            setAttach('')
        }
    }, [mode])

    const getScopeOptions = () => scopes.map(_scope => {
        return {
            label: `${_scope.name} (${_scope.quantity})`,
            value: _scope._id
        }
    })


    const handleSave = (values) => {
        onOk(values)
    }

    const handleOkClick = () => {
        form.submit()
    }

    const attachScope = () => {
        setAttach('scope')
    }

    return <ModalWindow title={'Edit Dashboard categories'}
                        modalProps={{width: 900, minHeight: 600}}
                        isModalVisible={isModalVisible}
                        scrollVisible={true}
                        onOk={handleOkClick} onCancel={onCancel}>
        <StandardForm form={form} onFinish={handleSave} fields={getValuesFields()}/>
        {attach === 'scope' && <Form form={form} onFinish={handleSave}>
            <Form.Item
                key={'scope_id'}
                label={<b>Scope</b>}
                name={'scope_id'}
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: "Please input scope or delete this field.",
                    },
                ]}
            >
                <Select
                    placeholder="Scope"
                    options={getScopeOptions()}
                />
            </Form.Item>
        </Form>}
        <div>
            {attach !== 'scope' && <Button
                type="dashed"
                onClick={attachScope}
                style={{
                    width: '60%'
                }}
                icon={<PlusOutlined/>}
            >
                Attach Scope
            </Button>}
        </div>
        <Space />
        <div style={{marginTop: 15}}>

        <Form form={form} onFinish={handleSave}>
        <Form.List name="includes">
            {(fields, { add, remove }) => (
                <>
                    {fields.map(({ key, name, ...restField }) => (
                        <Space
                            key={key}
                            style={{
                                display: 'block',
                                marginBottom: 8,
                            }}
                            align="baseline"
                        >
                            <div>Category: <MinusCircleOutlined onClick={() => remove(name)} /></div>
                            <Form.Item
                                {...restField}
                                name={[name, 'category']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Missing category name',
                                    },
                                ]}
                            >
                                <Input placeholder="Category Name" />
                            </Form.Item>
                            <Form.Item
                                {...restField}
                                name={[name, 'category_description']}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input placeholder="Category description" />
                            </Form.Item>
                            <Form.Item
                                {...restField}
                                name={[name, 'scope_id']}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Scope"
                                    options={getScopeOptions()}
                                />
                            </Form.Item>
                        </Space>
                    ))}
                    <Form.Item>
                        <Button type="dashed" onClick={() => add()}
                                style={{
                                    width: '60%',
                                }}
                                block icon={<PlusOutlined />}>
                            Add Sub category
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
        </Form>
        </div>

    </ModalWindow>

}

export class DashboardManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            projectId: getProjectId(this.props.location),
            scopes: [],
            hasRights: false,
            isModalVisible: false
        }
    }

    checkAdminPermission = () => {
        checkAnyOfPermissions([ 'admin', 'superadmin', 'material-analyst', ])
            .then(() => this.setState({hasRights: true}))
            .catch(() => this.setState({hasRights: false}))
    }

    componentDidMount() {
        this.getCategories();
        this.getScopes()
        this.checkAdminPermission()
    }

    getCategories = () => {
        this.setState({loading: true})
        api.get(`/projects/${this.state.projectId}/dashboard/categories`)
            .then(json => this.setState({
                categories: json.data,
            }))
            .finally(() => this.setState({loading: false}))
    }

    getScopes = () => {
        api.get(`/projects/${this.state.projectId}/iterations/scopes`).then(json => this.setState({scopes: json.data.items}))
    }

    deleteCategory = (row) => {
        api.delete(`/projects/${this.state.projectId}/dashboard/category?categoryId=${row._id}`)
            .then(json => {
                message.success('The category was successfully deleted!')
                this.getCategories()
            })
    }

    getColumns = () => {
        let categories =  [{
            dataIndex: 'category',
            title: 'Category'
        }, {
            dataIndex: 'scope_id',
            title: 'Attached Scope',
            render: scope_id => <div>
                {this.state.scopes.find(_scope => scope_id === _scope._id) && this.state.scopes.find(_scope => scope_id === _scope._id)['name']}
            </div>
        }]

        if (this.state.hasRights) {
            categories.push({
                dataIndex: 'category',
                title: 'Actions',
                width: "10%",
                render: (category, row) => this.state.hasRights && <div>
                    <EditOutlined className={"beautiful-link"} onClick={() => this.openModal('edit', row)}/>
                </div>
            })
            categories.push({
                dataIndex: 'category',
                title: '',
                width: "10%",
                render: (category, row) => this.state.hasRights && <div>
                    <Popconfirm title={`Are you sure you want to delete the ${category} category?`}
                                onConfirm={() => this.deleteCategory(row)}>
                        <DeleteOutlined className={"beautiful-link"}/>
                    </Popconfirm>
                </div>
            })
        }

        return categories
    }

    closeModal = () => {
        this.setState({isModalVisible: false})
        this.getCategories()
    }

    handleSave = newCategory => {
        message.info('Start editing the category...')
        api.post(`/projects/${this.state.projectId}/dashboard/category/add`, newCategory)
            .then(json => {
                message.success('Success')
                this.closeModal()
            })
            .catch(() => message.error('Something went wrong'))
    }

    openModal = (mode, initialValues = {}) => {
        this.setState({
            mode,
            isModalVisible: true,
            initialValues
        })
    }

    render() {
        return <div style={{padding: 5}}>
            <Spin spinning={this.state.loading}>
            <DataCard style={{height: 'calc(100vh - 75px)'}}>


                {this.state.hasRights && <Button style={{float: 'right', margin: "5px 0px"}}
                         onClick={() => this.openModal("add")}>
                    Add category
                </Button>}
                    <StandardTable scroll={600} style={{height: 1000}} data={this.state.categories} columns={this.getColumns()}/>

            </DataCard>
            </Spin>
            <EditDashboardCategory isModalVisible={this.state.isModalVisible}
                                   mode={this.state.mode}
                                   initialValues={this.state.initialValues}
                                   scopes={this.state.scopes}
                                   onCancel={this.closeModal}
                                   onOk={this.handleSave}/>
        </div>
    }

}
