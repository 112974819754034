import React from 'react';
import {ModalWindow} from "../helpers/modals/ModalWindow";
import {Button, Form, message, Radio, Spin, Upload} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {StandardModalForm} from "../helpers/modals/StandardModalForm";
import api from "../../api";
import {properties} from "../../properties";
import {Auth} from "@digatex/digatex-ui-lib";

export function ImportScopeModal({isModalVisible, setIsModalVisible, projectId, onFinish, spin, setLoading}) {

    const close = () => setIsModalVisible(false);

    const saveEditValues = (values) => {
        console.log(values)
        setLoading(true)
        let payload = {
            name: values.name,
            description: values.description,
            filename: values.filename.file.name,
        }

        api.post(`/projects/${projectId}/scopes/import`, payload).then(json => {
            onFinish()
        }).finally(() => setLoading(false))
    }

    const fields = [
        {label: 'Scope Name', name: 'name', _type: 'string', message: 'Error', required: true},
        {label: 'Scope Description', name: 'description', _type: 'long_text', message: 'Error', required: true},
        {label: 'File', name: 'filename', _type: 'file', message: 'Error', required: true, action: `${properties.apiUrl}/api/projects/${projectId}/scopes/import/save_file`,
            headers: Auth.headers(),}
    ]


    return <StandardModalForm fields={fields} isModalVisible={isModalVisible} closeModal={close}
                                    save={saveEditValues} spin={spin}/>

}
