import React from "react";
import {Steps} from "antd";
import {InfoCircleFilled} from "@ant-design/icons";

export const getIcon = (stepType, idx) => {
    if (stepType === 'approve') {
        return <div style={{backgroundColor: 'green', color: 'white'}}>{idx}</div>
    }
    return <div style={{backgroundColor: 'blue', color: 'white'}}>{idx}</div>
}

export const getDescription = _user => {
    if (_user.position) {
        return <p><b>{_user.position}</b>: {_user.user}</p>
    } else {
        return <p><b>{_user.user}</b></p>
    }
}

export function WorkflowVisualization({steps, active}) {





    const getSteps = () => {
        const items = []
        let i = 0;
        for (let step of steps) {
            i += 1
            items.push({
                title: step.title,
                icon: getIcon(step.step_type, i),
                description: step.users.map(v => getDescription(v)),
            },)
        }
        return items
    }

    return  <Steps
        direction="vertical"
        current={active || steps.length}
        items={getSteps()}
    />

}
