import React from "react";
import {getProjectId, labelMapper} from "../../helpers";
import api from "../../api";
import {DataCard} from "../helpers/cards/Card";
import {Col, Descriptions, Row, Statistic} from "antd";
import {WorkflowVisualization} from "./WorkflowVisualization";
import {GetBack} from "../helpers/cards/MaterialBlock";
import {projectCurrency, ROP_MIN_LABEL} from "../../properties";

export class WorkflowOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            workflow: {
                steps: []
            },
            projectId: getProjectId(this.props.location),
            workflowId: this.props.location.pathname.split('/')[4]
        }
    }

    componentDidMount() {
        api.get(`/projects/${this.state.projectId}/workflow/${this.state.workflowId}`)
            .then(json => {
                this.setState({workflow: json.data})
            })
    }

    labelNumbers = {
        gt: 'greater than',
        lt: 'less than',
        eq: 'equal to',
        high: 'High',
        any: 'Any',
        low: 'Low'
    }

    render() {

        const from = `/projects/${this.state.projectId}/workflow-settings`

        return (
            <div style={{margin: 10 }}>
                <Row>
                <Col span={1}>
                    <GetBack link={from}/>
                </Col>
                <Col span={10}>
                <DataCard title={'Workflow Info'}>
                    <Descriptions>
                        <Descriptions.Item span={3} label={<b>Workflow Name</b>}>{this.state.workflow.name}</Descriptions.Item>
                        <Descriptions.Item label={<b>Workflow Author</b>}>{this.state.workflow.author}</Descriptions.Item>
                    </Descriptions>
                </DataCard>
                </Col>
                    {this.state.workflow.isAutomatic && <Col style={{marginLeft: 10}} span={10}>
                        <DataCard title={'Criteria'}>
                            <Descriptions>
                                <Descriptions.Item span={1}
                                                   label={<b>{ROP_MIN_LABEL}</b>}>
                                    {labelMapper(this.state.workflow.criteria.rop)}</Descriptions.Item>
                                <Descriptions.Item
                                    label={<b>MAX</b>}>{labelMapper(this.state.workflow.criteria.max)}</Descriptions.Item>
                                {Object.keys(this.state.workflow.criteria).includes('item_type') && <Descriptions.Item span={1}
                                                    label={<b>Type</b>}>
                                    {this.state.workflow.criteria.item_type}</Descriptions.Item>}

                                {Object.keys(this.state.workflow.criteria).includes('criticality') && <Descriptions.Item span={1}
                                                                                                                       label={<b>Criticality</b>}>
                                    {labelMapper(this.state.workflow.criteria.criticality)}</Descriptions.Item>}

                                {Object.keys(this.state.workflow.criteria).includes('price') &&
                                    <Descriptions.Item span={3}
                                                       label={<b>Price</b>}>
                                        {this.labelNumbers[this.state.workflow.criteria.price.criteria]} <Statistic valueStyle={{fontSize: 14, marginLeft: 5}} value={this.state.workflow.criteria.price.value} suffix={projectCurrency} />
                                    </Descriptions.Item>}
                            </Descriptions>
                        </DataCard>
                    </Col>}
                </Row>

                <DataCard style={{marginTop: 10}}>
                    <Descriptions>
                        <Descriptions.Item label={<b>Workflow Steps</b>} children={null} />
                    </Descriptions>
                    <Row>
                        <Col span={24}>
                            <WorkflowVisualization steps={this.state.workflow.steps} />
                        </Col>
                    </Row>
                </DataCard>
            </div>
        );
    }

}
