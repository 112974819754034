import React from 'react';
import {DataCard} from "../helpers/cards/Card";
import api from "../../api";
import axios from "../../api";
import {Col, Descriptions, message, Row, Tag} from "antd";
import {Statistic} from "antd/es";
import {StandardTable} from "../helpers/TablePanel";
import {MaterialBlock, ScenarioLink} from "../helpers/cards/MaterialBlock";
import {CustomTabs} from "../helpers/CustomTabs";
import * as Auth from "../../AuthService";
import {downloadExcel} from "../../helpers";
import {DownloadOutlined} from "@ant-design/icons";
import {projectCurrency, ROP_MIN_LABEL} from "../../properties";
import Search from "antd/es/input/Search";
import {FilterRepresentation} from "../exception-reporting/ExceptionReporting";
import {labels} from "./ItemsListTable";


export class ScopePage extends React.Component {

    calcTypeLabels = {
        standard: 'High Turnover',
        recommendation: 'Best Recommendation',
        naive: 'Statistical Forecasting (naive)'
    }

    constructor(props) {
        super(props);
        this.state = {
            scopeId: window.location.pathname.split('/')[4],
            projectId: window.location.pathname.split('/')[2],
            scopeInfo: {},
            itemInfo: {
                'material': window.location.pathname.split('/')[4],
            },
            overview: {},
            page: 1,
            search: '',
            query: '',
            labels: {},
            activeTab: 'items',
            size: 10,
            inApproval: [],
            scenarios: [],
            total: 0,
            loading: false,
            scopeData: []
        }
    }

    getScopeInfo = () => {
        // if (this.state.projectId && this.state.scopeId) {
        api.get(`/projects/${this.state.projectId}/scope/${this.state.scopeId}`)
            .then(json => {
                if (json.data) {
                    this.setState({scopeInfo: json.data})
                    if (Object.keys(json.data).includes('overview')) {
                        this.setState({'overview': json.data.overview})
                    } else {
                        this.getScopeOverview()
                    }
                } else {
                    message.error('Scope was already deleted!')
                }
            })
        // }
    }

    getScopeOverview = () => {
        if (this.state.projectId && this.state.scopeId) {
            api.get(`/projects/${this.state.projectId}/scope/${this.state.scopeId}/overview`)
                .then(json => this.setState({overview: json.data}))
        }
    }

    getScenarios = () => {
        const pagination = {
            current: 1,
            size: 50
        }
        const data = {
            pagination,
            _filters: {
                scope: [this.state.scopeInfo.name]
            }

        }

        if (this.state.projectId && this.state.scopeInfo) {
            api.post(`/projects/${this.state.projectId}/iterations`, data)
                .then(json => {
                    this.setState({scenarios: json.data.iterations})
                })
        }
    }

    getPrettyNumber = _number => {
        if (_number / 1000000 > 1) {
            return <Statistic title={`Total Value ${projectCurrency}`} value={Math.round(_number / 10000) / 100}
                              suffix={'m'}/>
        } else if (_number / 1000 > 1) {
            return <Statistic title={`Total Value ${projectCurrency}`} value={Math.round(_number / 10) / 100}
                              suffix={'K'}/>

        }
    }

    getScopeItems = (page = this.state.page, size = this.state.size, query = this.state.query) => {
        if (!this.state.projectId || !this.state.scopeId) {
            return;
        }

        this.setState({loading: true})
        if (size && size !== this.state.size || this.state.query !== query) {
            page = 1
        }
        api.get(`/projects/${this.state.projectId}/scope/${this.state.scopeId}/list?page=${page || this.state.page}&size=${size || this.state.size}&search=${query}`)
            .then(json => {
                this.setState({scopeData: json.data.items, total: json.data.total})
                if (page) {
                    this.setState({page: page})
                }
                if (size) {
                    this.setState({size})
                }
                if (query) {
                    this.setState({query})
                }
            })
            .finally(() => this.setState({loading: false}))
    }

    getLabels = () => {
        api.get(`/projects/${this.state.projectId}/materials/attributes`)
            .then(json => {
                let attributes = json.data;
                let mapping = {}
                for (let attr of attributes) {
                    mapping[attr.field] = attr.label;
                }
                this.setState({labels: mapping})
            })
    }

    componentDidMount() {
        this.getScopeInfo();
        this.getScopeItems()
        this.getScenarios()
        this.getLabels()
    }

    exportScope = () => {
        const hide = message.loading('Preparing Scope for download...', 0, () => {
        });

        const config = Auth.createConfig();
        config['responseType'] = 'blob';
        axios.get(`/projects/${this.state.projectId}/scope/${this.state.scopeId}/export`, config)
            .then(json => {
                downloadExcel(json.data, `Scope ${this.state.scopeInfo.name}.xlsx`)
            }).catch(error => {
            message.error('Something went wrong');
            console.log(error)
        }).finally(() => setTimeout(hide, 1))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.scopeInfo !== this.state.scopeInfo) {
            this.getScenarios()
        }
    }

    getColumns = () => [
        {
            title: 'Material',
            dataIndex: 'material',
            key: 'name',
            width: '8%',
            render: material => <MaterialBlock material={material}>{material}</MaterialBlock>

        }, {
            title: 'Material Type',
            dataIndex: 'Type',
            key: 'type',
            width: '8%'

        }, {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: '50%'

        }, {
            title: 'Demand',
            dataIndex: 'demand',
            key: 'demand',
        }, {
            title: 'Lead Time',
            dataIndex: 'lt_days',
            key: 'lt_days',
        }, {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        }, {
            title: ROP_MIN_LABEL,
            dataIndex: 'rop',
            key: 'min',
        }, {
            title: 'MAX',
            dataIndex: 'max',
            key: 'max',
        }
    ]

    getInApprovalColumns = () => [
        {
            title: 'Material',
            dataIndex: 'material',
            key: 'name',
            width: '8%',

            render: material => <MaterialBlock material={material}>{material}</MaterialBlock>
        }, {
            title: 'Material Type',
            dataIndex: 'type',
            key: 'type',
            width: '8%',

        }, {
            title: `Old ${ROP_MIN_LABEL}`,
            dataIndex: 'min',
            key: 'min',
        }, {
            title: `NEW ${ROP_MIN_LABEL}`,
            dataIndex: 'new_min',
            key: 'new_min',
        }, {
            title: 'Old MAX',
            dataIndex: 'max',
            key: 'max',
        }, {
            title: 'NEW MAX',
            dataIndex: 'new_max',
            key: 'new_max',
        },
    ]

    getScenarioColumns = () => [{
        title: 'Scenario',
        dataIndex: 'iteration_name',
        key: 'name',
        width: '10%',
        render: (name, item) => <ScenarioLink name={name} id={item['_id']}/>
    }, {
        title: 'Calculations Type',
        dataIndex: 'calculations_type',
        key: 'type',
        width: '10%',
        render: _type => this.calcTypeLabels[_type]
    }]

    onPageChange = (newPagination, _filters, _sorter) => {
        this.getScopeItems(newPagination.current, newPagination.pageSize)
    }

    onSearch = (search) => {
        this.getScopeItems(this.state.page, this.state.size, search)
    }

    getTabs = () => [{
        name: 'Scope Items',
        key: 'items',
        children: <div>
            <div>
                <b>Material Number:</b> <Search allowClear
                                                style={{'width': 200, marginBottom: 10}}
                                                value={this.state.search}
                                                onChange={event => this.setState({search: event.target.value})}
                                                onSearch={this.onSearch}/>
            </div>
            <StandardTable size={'small'}
                           loading={this.state.loading}
                           style={{width: '100%'}}
                           total={this.state.total}
                           currentPage={this.state.page}
                           columns={this.getColumns()}
                           scroll={450}
                           onChange={this.onPageChange}
                           pageSize={this.state.size}
                           data={this.state.scopeData}/>
        </div>
    }, {
        name: `Attached Scenarios (${this.state.scenarios.length})`,
        key: 'test',
        children: <StandardTable size={'small'}
                                 loading={this.state.loading}
                                 style={{width: '100%'}}
                                 columns={this.getScenarioColumns()}
                                 scroll={450}
                                 data={this.state.scenarios}/>
    }, {
        name: `Used filters`,
        key: 'filters',
        children: <div>
            {this.getFilterRepresentation()}
        </div>
    }]

    getFilterRepresentation = () => {
        const getTagColor = condition => {
            switch (condition) {
                case 'and':
                    return 'purple'
                case 'or':
                    return 'cyan'
                default:
                    return 'white'
            }
        }
        if (!Object.keys(this.state.scopeInfo).includes('criteria')) {
            return <div>Scope criteria wasn't saved!</div>
        } else {
            let criteria = this.state.scopeInfo['criteria']
            let blocks = []
            for (let x of criteria['or']) {
                if (Array.isArray(x)) {
                    blocks.push(<Tag style={{padding: 10, display: 'block'}}>
                        <FilterRepresentation break={true} filters={x} labels={{...labels, ...this.state.labels}}
                                              deleteFilter={null}/>
                    </Tag>)
                } else {
                    for (let cond in x) {
                        let title = x[cond].length > 1 ? <div>And</div> : <div/>
                        blocks.push(<Row style={{padding: 5}}><Tag icon={title} color={getTagColor(cond)}
                                                                   style={{padding: 10}}>
                            <FilterRepresentation breakLines={true} filters={x[cond]}
                                                  labels={{...labels, ...this.state.labels}} deleteFilter={null}/>
                        </Tag></Row>)
                    }
                }

            }

            return <Tag style={{padding: 10}} color={'magenta'} icon={<div>Or</div>}>{blocks}</Tag>
        }
    }

    render() {
        return (
            <>
                <Row>
                    <Col span={11}>
                        <DataCard style={{margin: 5}} title={<div
                            className='text-center'> Scope <b>{this.state.scopeInfo.name}</b>
                            {this.state.scopeInfo.name && <DownloadOutlined style={{marginLeft: 20, color: "#107E70"}}
                                                                            onClick={this.exportScope}/>}
                        </div>}>
                            <Row>
                                <Descriptions>
                                    <Descriptions.Item label={'Scope description'} labelStyle={{fontWeight: 'bold'}}>
                                        {this.state.scopeInfo.description}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Col span={8}>
                                    <Statistic title={'Items'} value={this.state.overview.quantity}/>
                                </Col>
                                <Col span={8}>
                                    <Statistic title={'Stock'} value={this.state.overview.stock}/>
                                </Col>
                                <Col span={8}>
                                    {this.getPrettyNumber(this.state.overview.value)}
                                </Col>
                            </Row>
                        </DataCard>
                    </Col>
                </Row>

                <DataCard style={{margin: 5}}>
                    <CustomTabs activeKey={this.state.activeKey}
                                defaultKey={this.state.activeKey}
                                tabsData={this.getTabs()}
                                onChange={activeKey => this.setState({activeKey: activeKey})}/>

                </DataCard>
            </>
        );
    }
}
