import * as ActionTypes from './Constants'
import * as Auth from '../AuthService'
import axios from "axios";
import {properties} from "../properties";
import api from "../api";
import {SELECTED_INFO} from "./Constants";

export const getDuplicatesInfo = () => dispatch => {
    api.get('/dashboard/duplicates/overview-info', Auth.createConfig()).then(response => {
        dispatch({
            type: ActionTypes.GET_DUPLICATES_INFO,
            duplicatesInfo: response.data
        });
    })

}

export const getOverviewInfo = (_type, projectId) => dispatch => {
    api.get(`/projects/${projectId}/dashboard/${_type}/overview-info`, Auth.createConfig()).then(response => {
        let actionType = ''
        switch (_type){
            case 'full':
                actionType = ActionTypes.GET_INVENTORY_INFO
                break;

            case 'duplicates':
                actionType = ActionTypes.GET_DUPLICATES_INFO;
                break;

            case 'obsoletes':
                actionType = ActionTypes.GET_OBSOLETE_INFO;
                break;

            case 'insurance':
                actionType = ActionTypes.GET_INSURANCE_INFO;
                break;

            case 'slow-moving':
                actionType = ActionTypes.GET_SLOW_MOVING_INFO;
                break;

            case 'no-bom':
                actionType = ActionTypes.GET_NO_BOM_INFO;
                break;

            default:
                break;
        }

        dispatch({
            type: actionType,
            info: response.data
        });

    })

}

export const getFilterValues = (_type, columns) => dispatch => {

    api.post('/filter-info?type='+ _type, {columns: columns}, Auth.createConfig()).then(response => {

        dispatch({
            type: ActionTypes.FILTER_VALUES,
            values: response.data
        });
    }).catch(error => console.log(error))

}

export const getDuplicatesItems = () => dispatch => {

    api.get( '/duplicates?from=10&count=50', Auth.createConfig()).then(response => {

        dispatch({
            type: ActionTypes.SELECT_ITEMS,
            selected: response.data
        });

        dispatch({
            type: ActionTypes.SELECT,
            title: 'Duplicates'
        })
    })

}

export const getObsoleteItems = () => dispatch => {

    api.get('/obsoletes', Auth.createConfig()).then(response => {

        dispatch({
            type: ActionTypes.SELECT_ITEMS,
            selected: response.data
        });

        dispatch({
            type: ActionTypes.SELECT,
            title: 'Obsoletes'
        })
    })

}

export const getInsuranceItems = () => dispatch => {

    api.get( '/insurance', Auth.createConfig()).then(response => {

        dispatch({
            type: ActionTypes.SELECT_ITEMS,
            selected: response.data
        });

        dispatch({
            type: ActionTypes.SELECT,
            title: 'Insurance Items'
        })
    })

}

export const getSlowMovingItems = () => dispatch => {

    api.get('/slow-moving', Auth.createConfig()).then(response => {

        dispatch({
            type: ActionTypes.SELECT_ITEMS,
            selected: response.data
        });

        dispatch({
            type: ActionTypes.SELECT,
            title: 'Slow Moving Items'
        })
    })

}

export const getNoBOMItems = () => dispatch => {

    api.get('/no-bom', Auth.createConfig()).then(response => {

        dispatch({
            type: ActionTypes.SELECT_ITEMS,
            selected: response.data
        });

        dispatch({
            type: ActionTypes.SELECT,
            title: 'No BOM Items'
        })
    })

}

export const getFullItems = () => dispatch => {

    api.get('/full', Auth.createConfig()).then(response => {

        dispatch({
            type: ActionTypes.SELECT_ITEMS,
            selected: response.data
        });

        dispatch({
            type: ActionTypes.SELECT,
            title: 'Client Inventory Info'
        })
    })

}

export const filter = (filters, title) => dispatch => {

    api.post('/filter', {'filters': filters, 'type': title}, Auth.createConfig()).then(response => {

        dispatch({
            type: ActionTypes.SELECT_ITEMS,
            selected: response.data
        });
    })

}

export const getSelectedInfo = (_type, projectId) => dispatch => {
    api.get(`/projects/${projectId}/dashboard/${_type}/overview-info`, Auth.createConfig()).then(response => {

        dispatch({
            type: SELECTED_INFO,
            info: response.data
        });

    })

}

export const getSelectedExamples = (_type, pagination, sorters, filters) => dispatch => {
    api.get(`/dashboard/${_type}?page=${pagination.current}&size=${pagination.pageSize}`, Auth.createConfig()).then(response => {

        dispatch({
            type: ActionTypes.SELECT_ITEMS,
            selected: response.data.items
        });

        dispatch({
            type: ActionTypes.TOTAL_QUANTITY,
            total: response.data.total
        });
    })

}


export const searchByMaterial = (query, type) => dispatch => {

    axios.get(properties.apiUrl + `/api/search?query=${query}&type=${type}`, Auth.createConfig()).then(response => {

        if (response.data.data.length!==0) {

            dispatch({
                type: ActionTypes.SELECT_ITEMS,
                selected: response.data
            });

            dispatch({
                type: ActionTypes.SELECT,
                title: 'Search results for ' + query
            })

            dispatch({
                type: ActionTypes.LAST_CATEGORY_SELECTED,
                value: type
            })
        } else {
            alert('The material wasn\'t found in the category you selected!')
        }
    })

}

