import React from "react";
import { Line } from 'react-chartjs-2';
import {properties} from "../../properties";


export function ForecastingGraph ({data, labels, colors=[], legendFields, height, titleLabels}) {

    const options = {
        scales: {
            y: {
                ticks: {
                    min: 0,
                    stepSize: 1
                }
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    title: (xDatapoint) => {
                        return `Date: ${xDatapoint[0].label}`},
                    label: (yDatapoint) => {
                        if (yDatapoint.dataset.label.includes('Stock')) {
                            let element = data[yDatapoint.dataIndex]
                            return `${yDatapoint.dataset.label} - ${yDatapoint.formattedValue} (GI: ${element.gi}, GR: ${element.gr})`
                        }
                        return `${yDatapoint.dataset.label} - ${yDatapoint.formattedValue}`
                    },
                }
            }
        },
        annotation: {
            annotations: [
                {
                    drawTime: "afterDatasetsDraw",
                    type: "line",
                    mode: "vertical",
                    scaleID: "x-axis-0",
                    value: 5,
                    borderWidth: 5,
                    borderColor: "red",
                    label: {
                        content: "Current Month",
                        enabled: true,
                        position: "top"
                    }
                }
            ]
        },
    };

    const getColor = (label, item) => {
        if (label === 0 && item['type'] === 'history') {
            return 'gray'
        }

        return colors[label] || 'black'
    }

    const getData = (item, label) => {
        if (label !== 'stock_level') {
            return item[label] !== '' ? item[label] : null
        }
        if (item.type === 'history') {
            return item[label]
        }
    }

    const getDataSets = () => {
        let future = [{
            label: `Future Stock`,
            data: data.map(s => s.type === 'future' ? (s['stock_level'] !== '' ? s['stock_level'] : null) : null),
            borderColor: 'blue',
            backgroundColor: 'blue',
            fill: false,
            stepped: true,
        }]
        let history = labels.map((v, i) => {
            return {
                label: legendFields[i],
                data: data.map(s => getData(s, v)),
                borderColor: data.map(s => getColor(i, s)),
                backgroundColor: data.map(s => getColor(i, s)),
                fill: false,
                stepped: true,
                colors: ['', 'red', 'green', 'blue']
            }
        })


        return future.concat(history)

    }

    const getChartData = () => {
        const chartData =  {
            labels: data.map(s => s['date']),
            datasets: getDataSets()
        };

        chartData['datasets'].push({
            label: 'Zero point',
            data: data.map(s => 0),
            borderColor: 'yellow',
            backgroundColor: '#FFFFE0',
            fill: false,
        })

        return chartData
    }

    return (
        <Line style={{height: height}} data={getChartData()} options={options} />
    )

}
