import React, {useEffect, useState} from "react";
import api from "../../api";
import {useForm} from "antd/es/form/Form";
import {Button, Popover, Tabs} from "antd";
import {StandardForm} from "./forms/StandardForm";

const {TabPane} = Tabs;

export const stringOptions = [
    {
        value: 'full',
        label: 'Full Search'
    }, {
        value: 'partial',
        label: 'Partial Search'
    },
    {
        value: 'not_include',
        label: 'Not include'
    }, {
        value: 'startsWith',
        label: 'Starts With'
    }
];

export const numberOptions = [
    {
        value: 'gt',
        label: 'Greater than'
    }, {
        value: 'eq',
        label: 'Equal to'
    }, {
        value: 'lt',
        label: 'Less than'
    }
]

export const dateOptions = [
    {
        value: 'gt',
        label: 'Greater than'
    }, {
        value: 'eq',
        label: 'Equal to'
    }, {
        value: 'lt',
        label: 'Less than'
    }
]

export const arrayOptions = [
    {
        value: 'full',
        label: 'Full Search'
    }, {
        value: 'partial',
        label: 'Partial Search'
    }, {
        value: 'not_include',
        label: 'Not include',
    }, {
        value: 'startsWith',
        label: 'Starts With'
    }
]

export function AdvancedFilter({projectId, setFilter, filters, labels = {}}) {

    const [attributes, setAttributes] = useState([])

    const [selected, setSelected] = useState({})
    const [proposedValues, setProposedValues] = useState([])

    const [open, setOpen] = useState(false);

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    useEffect(() => {
        api.get(`/projects/${projectId}/materials/attributes`)
            .then(json => setAttributes(json.data))
    }, [])


    const createAttributes = () => {
        return attributes.map(a => {
            return {
                type: a.type,
                value: a.field,
                label: a.label
            }
        });
    }

    const AttributesFields = [
        {
            label: 'Attribute Name',
            name: 'name',
            _type: 'select-object',
            message: 'Error',
            required: true,
            options: createAttributes()
        },
    ]

    const addFilter = (values) => {

        let value = values['value']
        if (selected.type === 'date') {
            if (value === undefined) {
                value = new Date()
            } else {
                value = value.toDate()
            }
        }

        setFilter([...filters, {
            key: values['name'],
            value: value,
            'mode': values['mode'],
            type: selected.type
        }])
        setOpen(false)
        attrNameForm.resetFields(['name', 'value'])
    }

    const [attrNameForm] = useForm()

    const [attrValueForm] = useForm()

    const [attrModeForm] = useForm()

    useEffect(() => {
        if (selected && selected.field) {
            getProposedValues()
            attrModeForm.resetFields(['mode'])
            attrValueForm.resetFields(['value'])
        } else {
            attrNameForm.resetFields(['name', 'value'])
        }
    }, [selected])


    useEffect(() => {
            if (open) {
                setSelected({})
            }
        }, [open]
    )

    const onFieldsChange = (fields) => {
        if (fields[0].name[0] === 'name') {
            setSelected(attributes.find(x => x['field'] === fields[0].value))
        }
    }

    const getOptionsByType = () => {
        if (selected.type === 'text') {
            return stringOptions
        } else if (selected.type === 'number') {
            return numberOptions
        } else if (selected.type === 'array') {
            return arrayOptions
        } else if (selected.type === 'date') {
            return dateOptions
        }
        return []
    }

    const getProposedValues = () => {
        api.get(`/projects/${projectId}/materials/attributes/values?attr=${selected.field}`)
            .then(json => {
                setProposedValues(json.data)
            })
    }

    const getProposedOptions = () => {
        return proposedValues
    }

    const getModeValue = () => {
        return [{
            label: 'Search mode',
            name: 'mode',
            _type: 'select-object',
            message: 'Error',
            required: true,
            options: getOptionsByType()
        }]
    }

    const getValuesFields = () => {
        if (selected.type === 'date') {
            return [{
                label: 'Attribute Value', name: 'value', _type: 'date',
            }]
        }
        return [{label: 'Attribute Value', name: 'value', _type: 'autocomplete', options: getProposedOptions()}]
    }

    const addFilterFull = () => {
        const result = {
            name: attrNameForm.getFieldValue("name"),
            value: attrValueForm.getFieldValue("value"),
            mode: attrModeForm.getFieldValue("mode"),
            type: selected.type
        }
        addFilter(result)
    }

    const getDefaultMode = () => {
        if (selected.type === 'text') {
            return 'full'
        } else if (selected.type === 'number') {
            return 'eq'
        } else if (selected.type === 'array') {
            return 'full'
        }
        else if (selected.type === 'date') {
            return 'eq'
        }
        return ''
    }

    const content = <div style={{width: 350}}>
        <Tabs defaultActiveKey={"1"}>
            <TabPane tab={'Attribute'} key={"1"}>
                <StandardForm onFieldsChange={onFieldsChange} form={attrNameForm} fields={AttributesFields}
                              onFinish={addFilter}/>

                {selected && selected.field && <StandardForm form={attrValueForm} fields={getValuesFields()}
                                                             onFinish={(val) => console.log(val)}/>}


                {(selected && selected.field && getOptionsByType().length > 0) &&
                    <StandardForm form={attrModeForm} initialValues={{mode: getDefaultMode()}} fields={getModeValue()}
                                  onFinish={() => console.log()}/>}

                <Button className={'submit-button'} style={{float: 'right'}}
                        onClick={() => addFilterFull()}>Submit</Button>
            </TabPane>
        </Tabs>
    </div>

    return <Popover open={open} onOpenChange={handleOpenChange} placement="bottom" content={content} title="Advanced Filter"
                    trigger="click">
        <Button onClick={() => setOpen(true)}
                size="small"
                icon={<img src={process.env.PUBLIC_URL + '/images/filter_list-24px.svg'}/>}

        >
            Add Filter
        </Button></Popover>

}
