import React, {useEffect, useState} from 'react'
import api from "../../api";
import {Col, Descriptions, message, Row, Statistic, Tag} from "antd";
import {DataCard} from "../helpers/cards/Card";
import DescriptionsItem from "antd/es/descriptions/Item";
import {DoughnutGraph} from "../helpers/DoughnutGraph";
import {CustomTabs} from "../helpers/CustomTabs";
import {StandardTable} from "../helpers/TablePanel";
import {ExceptionStatusTag, TemplatesTable} from "./ExceptionReporting";
import {MaterialBlock} from "../helpers/cards/MaterialBlock";
import Highlighter from "react-highlight-words";
import {properties} from "../../properties";
import {createMINMAxLink, downloadExcel, openLinkInNewTab} from "../../helpers";
import {DownloadOutlined} from "@ant-design/icons";
import * as Auth from "../../AuthService";


export function ReportOverview({}) {

    const pathname = window.location.pathname.split('/')
    const [activeKey, setActiveKey] = useState('')
    const [update, setUpdate] = useState(false)

    const [projectId, setProjectId] = useState()
    const [reportId, setReportId] = useState(pathname[4])

    const [reportOutputTotal, setReportOutputTotal] = useState(0)
    const [report, setReport] = useState({})

    const getReportData = () => {
        api.get(`/projects/${pathname[2]}/exception-reporting/reports?report_id=${reportId}`)
            .then(json => {
                setReport(json.data)
                setProjectId(pathname[2])
                setActiveKey('items')
                setUpdate(!update)
            })
    }


    useEffect(() => {
        getReportData()
    }, []);

    if (!report || !report.template_info) {
        return null
    }

    const columns = () => {
        return [
            {
                title: 'Material',
                dataIndex: 'material',
                key: 'material',
                render: material => <MaterialBlock material={material} >{material}</MaterialBlock>
            },
            {
                title: 'Material Description',
                dataIndex: 'description',
                key: 'Material Description',
            },
            {
                title: 'Type',
                dataIndex: 'Type',
                key: 'Type',
                filters: [
                    {
                        text: 'High Turnover',
                        value: 'High Turnover',
                    },
                    {
                        text: 'Low Turnover',
                        value: 'Low Turnover',
                    },
                    {
                        text: 'Slow Moving',
                        value: 'Slow Moving',
                    },   {
                        text: 'Non Moving',
                        value: 'Non Moving',
                    },
                ]
            },
            {
                title: 'Status',
                dataIndex: 'message',
                key: 'message',
                render: (message, item) => <ExceptionStatusTag condition={item} />
            },
            {
                title: 'Open',
                dataIndex: 'material',
                key: 'Material',
                render: material => {
                    return <div className={'beautiful-link'}
                                onClick={() => window.open(createMINMAxLink(material, projectId, ''))}>
                        Open
                    </div>
                }
            },

        ]
    }

    const downloadReport = () => {
        message.info("Start exporting the exception report...")
        const config = Auth.createConfig();
        config['responseType'] = 'blob';
        api.get(`/projects/${projectId}/exception-reporting/reports/download?report_id=${reportId}`, config)
            .then(json => downloadExcel(json.data, `Exception Report.xlsx`))
            .catch(error => {
                console.log(error)
            })
    }

    const getTabs = () => [ {
        name: `Items (${reportOutputTotal})`,
        key: 'items',
        children: <TemplatesTable columns={columns()}
                                  projectId={projectId}
                                  update={update}
                                  setTotalCount={total => setReportOutputTotal(total)}
                                  url={`/projects/${projectId}/exception-reporting/reports/output?report_id=${reportId}&`} />
    },{
        name: 'Statistics',
        key: 'statistics',
        children:<div>
            <DataCard title={'Statistics'}>
                {report.template_info.conditions.map(_cond => {

                    const percentage = Math.round(report.statistics[_cond.message].quantity / report.statistics[_cond.message].total_value * 100) || 0
                    const prefix = ` (${percentage}%) /`

                    const graphLabels = ['Matched', 'Total'].map(v => v === 'Total' ? 'Rest' : v)

                    return <div style={{margin: 5}}>
                        <ExceptionStatusTag condition={_cond} />

                        <Statistic className='text-center'
                                   valueStyle={{fontSize: 18}}
                                   suffix={<Statistic valueStyle={{fontSize: 18}}
                                                      prefix={prefix}
                                                      value={report.statistics[_cond.message].total_value}/>}

                                   value={report.statistics[_cond.message].quantity}/>

                        <DoughnutGraph data={[percentage, 100 - percentage]}
                                       labels={['Number of materials in scope matching the criteria']}
                                       legendLabels={graphLabels}
                                       height={90}
                                       showLegend={false}/>
                    </div>
                })}
            </DataCard>
        </div>
    },]

    return <div>
        <Row>
            <Col span={24} style={{padding: 10}}>
                <DataCard title={<div>{report.report_name} <DownloadOutlined onClick={() => downloadReport()} className={'beautiful-link'} /></div>}>
                    <Descriptions>
                        <DescriptionsItem labelStyle={{fontWeight: 'bold'}} span={3}
                                          label={"Template name"}>{report.template_info.template_name}</DescriptionsItem>
                        <DescriptionsItem labelStyle={{fontWeight: 'bold'}} span={3}
                                          label={"Template description"}>{report.template_info.template_description}</DescriptionsItem>
                        <DescriptionsItem labelStyle={{fontWeight: 'bold'}} span={3}
                                          label={"Scope Name"}>{report.scope_name}</DescriptionsItem>

                    </Descriptions>
                </DataCard>
            </Col>
        </Row>
        <Row>
            <Col span={24} style={{padding: 10}}>
                <CustomTabs activeKey={activeKey}
                            defaultKey={activeKey}
                            tabsData={getTabs()}
                            onChange={activeKey => setActiveKey(activeKey)}/>
            </Col>
        </Row>

    </div>

}
