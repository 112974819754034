import {ModalWindow} from "./ModalWindow";
import React, {useEffect, useState} from "react";
import api from "../../../api";
import {CorstonGraph} from "../../min-max-item/CorstonGraph";
import {Spin} from "antd";
import {START_CROSTON} from "../../../properties";

export function CorstonModal({isModalVisible, setModalVisible,material, projectId}) {

    return <ModalWindow title={'Croston Simulation'}
                        isModalVisible={isModalVisible}
                        onOk={() => setModalVisible(false)}
                        scrollVisible={false}
                        onCancel={() => setModalVisible(false)}>

        <CrostonForecast show={isModalVisible} material={material} projectId={projectId}/>
    </ModalWindow>
}


export function CrostonForecast ({show, material, projectId}) {
    const [graphData, setGraphData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (show) {
            setLoading(true)
            api.get(`/projects/${projectId}/material-analysis/${material}/corston?date_from=${START_CROSTON}&date_to=2023-12`)
                .then(json => {
                    let data = json.data.reverse()
                    for (let item of data) {
                        if (item['Quantity'] === 0) {
                            item['Quantity'] = null
                        } else {
                            break
                        }
                    }
                    setGraphData(data.reverse())
                })
                .finally(() => setLoading(false))
        } else {
            setGraphData([])
        }
    }, [show])

    return <div>
        <Spin spinning={loading}>
            <CorstonGraph data={graphData}
                          legendFields={['Actual Demand', 'Croston', 'Exponential', 'Croston TSB']}
                          colors={['#3F5FFF66', '#FFAB0066', '#B3000066', '#00D30966']}
                          labels={['Quantity','Forecast', 'SE (roundof)', 'forecast TSB']} />
        </Spin>
    </div>
}
