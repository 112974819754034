import React from "react";
import {DataCard} from "../helpers/cards/Card";
import {Button, Input, message, Select} from "antd";
import {StandardTable} from "../helpers/TablePanel";
import {EditWorkflowModel} from "./EditWorkflowModel";
import api from "../../api";
import {checkAnyOfPermissions, getProjectId} from "../../helpers";
import {DeleteOutlined} from "@ant-design/icons";
import Search from "antd/es/input/Search";

const { Option } = Select;

export class WorkflowsPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            total: 0,
            page: 1,
            search:'',
            options: {},
            filters:{},
            pageSize: 10,
            results: [],
            hasRights: false,
            isModalVisible: false,
            projectId: getProjectId(this.props.location)
        }
    }

    checkAdminPermission = () => {
        checkAnyOfPermissions([ 'admin', 'superadmin', 'material-analyst' ])
            .then(() => this.setState({hasRights: true}))
            .catch(() => this.setState({hasRights: false}))
    }

    getFilterOptions = field => {
        api.get(`/projects/${this.state.projectId}/workflow/filter/options?field_name=${field}`)
            .then(res => {
                this.setState({options: {
                    ...this.state.options, [field]: res.data
                    }})
            })
    }

    getWorkflows = (page = this.state.page, size = this.state.pageSize, search=this.state.search, filters = this.state.filters) => {
        this.setState({loading: true});
        if (size !== this.state.pageSize || search !== this.state.search || filters !== this.state.filters) {
            if (Object.keys(this.state.filters).length > 0 || Object.keys(this.state.filters).length > 0) {
                page = 1
            }
        }
        api.post(`/projects/${this.state.projectId}/workflows?page=${page}&size=${size}&search=${search}`, filters)
            .then(json => this.setState({
                results: json.data.data,
                total: json.data.total,
                page: page,
                filters: filters,
                search: search,
                pageSize: size
            })).finally(() => this.setState({loading: false}));
    }

    onChange = (pagination, filters, sorters) => {
        console.log(pagination)
        this.getWorkflows(pagination.current, pagination.pageSize, this.state.search, filters)
    }


    createFilterOptions = field => {
        if (field === "isAutomatic") {
            return this.state.options[field] && this.state.options[field].map(v => {
                return  {
                    text: v ? 'Yes' : 'No',
                    value: v,
                }
            })
        } else {
            return this.state.options[field] && this.state.options[field].map(v => {
                return {
                    text: v,
                    value: v,
                }
            })
        }
    }

    componentDidMount() {
        this.getWorkflows()
        this.getFilterOptions('author')
        this.getFilterOptions('isAutomatic')
        this.checkAdminPermission()
    }

    onWorkflowDelete = (id) => {
        this.setState({loading: true})

        api.delete(`/projects/${this.state.projectId}/workflows/${id}`)
            .then(json => {
                message.success(json.data.msg)
                this.getWorkflows()
            }).catch(error => message.error(error.data.msg))
            .finally(() => this.setState({loading: false}))

    }

    columns = () => {

        const columns = [{
            title: 'Workflow Name',
            dataIndex: 'name'
        }, {
            title: 'Workflow Creator',
            dataIndex: 'author',
            filters: this.createFilterOptions('author')
        }, {
            title: 'Is automated',
            dataIndex: 'isAutomatic',
            render: isAutomated => isAutomated ? 'Yes' : 'No',
            filters: this.createFilterOptions('isAutomatic')

        }]

        if (this.state.hasRights) {
            columns.push({
                title: 'Actions',
                dataIndex: '_id',
                render: _id => <a href={`/projects/${this.state.projectId}/workflow-settings/${_id}`}>Open</a>
            })

            columns.push({
                dataIndex: '_id',
                render: _id => <DeleteOutlined onClick={() => this.onWorkflowDelete(_id)} className={'beautiful-link'} />
            })
        }
        return columns
    }

    handleModalVisibility = status => {
        this.setState({isModalVisible: status})
    }

    handleSave = () => {
        this.handleModalVisibility(false)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.isModalVisible !== this.state.isModalVisible && !this.state.isModalVisible) {
            this.getWorkflows()
        }
    }

    onSearch = search => {
        this.getWorkflows(1, this.state.pageSize, search)
    }

    render() {

        const style = {
            height: '90vh', maxHeight: '90vh', margin: 10
        }

        return (
            <DataCard style={style}>
                <EditWorkflowModel isModalVisible={this.state.isModalVisible}
                                   projectId={this.state.projectId}
                                   onCancel={() => this.handleModalVisibility(false)}
                                   onOk={this.handleSave}/>
                {this.state.hasRights && <Button style={{float: "right", marginBottom: 5}}
                         onClick={() => this.handleModalVisibility(true)}>Add new Workflow</Button>}

                <div style={{marginTop: 10, marginBottom: 10}}>
                    <Search
                        placeholder="Search by workflow name"
                        allowClear
                        enterButton="Search"
                        onSearch={this.onSearch}
                    />
                </div>
                <StandardTable size={'small'}
                               loading={this.state.loading}
                               style={{width: '100%', height: '90%'}}
                               total={this.state.total}
                               currentPage={this.state.page}
                               columns={this.columns()}
                               scroll={500}
                               onChange={this.onChange}
                               pageSize={this.state.pageSize}
                               data={this.state.results}/>
            </DataCard>
        );
    }

}
