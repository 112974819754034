import React, {Component} from "react";
import axios from "../../api";
import {Table} from "antd";
import {connect} from "react-redux";
import * as Auth from '../../AuthService'
import {selectProject} from "../../actions/actions";
import {openLink} from "../../helpers";


export class ProjectPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projects: []
        }
    }


    componentDidMount() {
        axios.get('/projects', Auth.createConfig())
            .then(json => {
                this.setState({projects: json['data']})
                if (json['data'].length === 1) {
                    openLink(this.getHref(json['data'][0]));
                }
            })
    }

    getColumns = () => {
        return [
            {
                title: 'Project Name',
                dataIndex: 'project_name',
                key: 'project_name',
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                key: 'actions',
            }
        ];
    }

    getHref = (project) => {
        if (project.baseUrl.startsWith('http')) {
            return project.baseUrl
        } else {
            return `/projects/${project._id}/${project.baseUrl}`
        }
    }

    parseDataSource = () => {
        return this.state.projects.map(project => {
            return {
                project_name: project.project_name,
                actions: [<a href={this.getHref(project)} onClick={() => this.props.selectProject(project)}>Open project</a>]
            }
        })
    }

    render() {
        return (
            <div>
                <Table dataSource={this.parseDataSource()} columns={this.getColumns()} />
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        inventoryInfo: state.inventoryInfo,
        selectedProject: state.selectedProject
    }
}

export default connect(mapStateToProps, {
    selectProject
})(ProjectPanel)
