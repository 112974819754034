import React, {useState} from 'react';
import {DataCard} from "../helpers/cards/Card";
import {Button, DatePicker, Descriptions, message} from "antd";
import * as Auth from "../../AuthService";
import {downloadExcel} from "../../helpers";
import api from "../../api";

export function LeadTimeReport() {

    const [params, setParams] = useState({})

    const projectId = window.location.pathname.split('/')[2]

    const download = () => {
        const hide = message.loading('Preparing lead time report...', 0, () => {
        });

        const config = Auth.createConfig();
        config['responseType'] = 'blob';
        api.get(`/projects/${projectId}/lead-time/export?start_date=${params['start_date']}&end_date=${params['end_date']}`, config)
            .then(json => {
                downloadExcel(json.data, `Lead Time report.xlsx`)
            }).catch(error => {
                message.error(error)
        }).finally(() => setTimeout(hide, 1))
    }

    const handleSave = (values) => {
        let parsed = {}
        for (let key in values) {
            parsed[key] = values[key].format("YYYY-MM-DD")
        }
        setParams({...params, ...parsed})
    }

    return <DataCard title="Lead Time Report" style={{margin:10}}>
        <div>
            <Descriptions title="Used constants">
                <Descriptions.Item label="Start Date" span={3}>
                    <DatePicker format={'YYYY-MM-DD'} onChange={(date_, datestr) => handleSave({start_date: date_})}/>
                </Descriptions.Item>
                <Descriptions.Item label="End Date" span={3}>
                    <DatePicker format={'YYYY-MM-DD'} onChange={(date_, datestr) => handleSave({end_date: date_})}/>
                </Descriptions.Item>
            </Descriptions>
        </div>

        {(params['start_date'] && params['end_date']) && <div>
            <b>Report will be analysing data from {params['start_date']} till {params['end_date']}</b>
        </div>}

        <Button onClick={download} disabled={!params['start_date'] || !params['end_date']} >Download the report</Button>
    </DataCard>

}
