import React from "react";
import {DataCard} from "./cards/Card";
import {Button, Col, Row, Statistic, Tag} from "antd";
import {projectCurrency, properties} from "../../properties";
import {CaretDownOutlined, CaretLeftOutlined} from "@ant-design/icons";

function InfoRow({data, title, expandable, onClick, expandStatus, valueLabel}) {

    const mapping = {
        'quantity': 'Number of Lines',
        'stock': 'Number of Stocked Lines',
        'value': valueLabel
    }

    const cols = ['quantity', 'stock', 'value'].map(col => {
        return <Col xs={24} sm={24} md={24} lg={8}>
            <Statistic title={<div style={{fontSize: 12}}>{mapping[col]}</div>}
                       valueStyle={{fontSize: 20}} suffix={col === 'value' ? 'm' : ''}
                       value={data && Object.keys(data).includes(col) ? data[col] : 0}/>
        </Col>
    })

    return <div>
        <InfoTag title={title} expandable={expandable} onClick={onClick} expandStatus={expandStatus} />
        <Row>
            {cols}
        </Row>
    </div>
}


function InfoTag ({title, expandable, onClick, expandStatus}) {

    let icon = <div></div>;
    if (expandable) {
        icon = expandStatus ?
            <CaretDownOutlined style={{float: 'right'}} onClick={onClick} /> :
            <CaretLeftOutlined style={{float: 'right'}}  onClick={onClick} />
    }

    return  <Row style={{margin: '5px 0'}}>
        <Col span={24}>
            {icon}
            <Tag style={{float: 'right'}} color={properties.kbrColors.pantone301Blue}>{title} </Tag>

        </Col>
    </Row>
}

export class InfoPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expandStatus: false
        }
    }

    onExpand = () => {
        this.setState({expandStatus: !this.state.expandStatus})
    }


    getBackgroundColor() {
        const title = this.props.title;
        if (this.props.searched) {
            return 'rgba(0,120,63,0.2)'
        }
        return title===this.props.selected ? 'rgba(162,199,226,0.2)' : ''
    }

    render() {

        const info = this.props.info;
        const title = this.props.title;

        return (
            <DataCard title={<div className='text-center' style={{fontSize: 18, display: 'inline'}}>{title}
                {this.props.showExpl && <Button style={{float: 'right', marginTop: -25}} onClick={this.props.openModal}>?</Button>}</div>} style={{margin: 15, backgroundColor: this.getBackgroundColor()}}>
                <InfoRow data={info.total} valueLabel={`Total Value (${projectCurrency})`} title={'Total Summary'} expandable={true} expandStatus={this.state.expandStatus} onClick={this.onExpand} />
                {this.state.expandStatus && <div>
                    <InfoRow data={info.unpriced} valueLabel={`Estimated Value (${projectCurrency})`} title={'Unpriced Summary'}/>
                    <InfoRow data={info.priced} valueLabel={`Calculated Value (${projectCurrency})`} title={'Priced Summary'}/>
                </div>}

            </DataCard>
        );
    }

}
