import React, {Component} from "react";
import {Button, Form, Input, Modal, Popconfirm, Select} from "antd";
import axios from "../../api";
import * as Auth from '../../AuthService'
import {Option} from "antd/es/mentions";
import api from "../../api";
import {AVE_TIME} from "../../properties";

export class MinMaxModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            settings: {
                constants: {
                    service_factor_table: []
                }
            },
            scopes: [],
            calcType: 'recommendation',
            method: 'naive',
            ml: false,
            scope: this.props.scopes[0]
        }
    }

    form = React.createRef();

    handleCancel = () => {
        this.props.closeModal();
    };

    handleFieldChange = (changedFields) => {
        for (let field of changedFields) {
            if (field.name[0] === 'calculations_type') {
                this.setState({calcType: field.value})
            }
            if (field.name[0] === 'scope') {
                this.setState({scope: this.props.scopes[field.value]})
            }
        }
    }

    onFinish = (values) => {
        const constants = this.state.settings.constants;
        let typeData = {}
        if (values.calculations_type === 'standard') {
            typeData = {
                csf: constants.service_factor_table[values.csf].csf,
                service_level: constants.service_factor_table[values.csf].sl,
                mad: parseFloat(values.mad),
                holding: parseFloat(values.holding) / 100,
                s: parseFloat(values.s),
                default_price: constants.default_price,
            }
        } else if (values.calculations_type === 'recommendation') {
            typeData = {
                forecastingMethod: values.forecasting,
                ml: values.ml,
                recalc_interval: values.recalc_interval
            }
        }
        const data = {
            iteration_name: values.name,
            scope: this.props.scopes[values.scope],
            calculations_type: values.calculations_type,
            status: 'scenarioProcessing',
            constants: {
                ...typeData
            }
        }
        api.post(`/projects/${this.props.projectId}/iteration/add`, data, Auth.createConfig())
            .then((json) => {
                this.props.closeModal()
            })
            .catch(error => {
                alert('Something went wrong. Try it out later')
                console.log(error)
            })
    };

    componentDidUpdate(prevProps) {
        if (prevProps.projectId !== this.props.projectId && this.props.projectId) {
            axios.get(`/projects/${this.props.projectId}/settings`, Auth.createConfig()).then(json => this.setState({settings: json.data}))
        }
        if (prevProps.scopes !== this.props.scopes) {
            this.setState({scope: this.props.scopes[0]})
        }
    }

    getTheIndexOfDefault() {
        const constants = this.state.settings.constants;
        const _default = constants.default_service_level;
        return constants.service_factor_table.findIndex(element => element.sl === _default.sl && element.csf === _default.csf)
    }

    getApproximateDuration = () => {
        if (this.state.scope) {
            let seconds = AVE_TIME * this.state.scope.quantity

            const getRemains = (_diff, base) => {
                return _diff % base
            }

            if (seconds < 60) {
                return `${getRemains(seconds, 60)} second(s)`;
            } else if (seconds < 3600) {
                return `${Math.floor(seconds / 60)} minute(s) ${getRemains(seconds, 60)} second(s)`;
            } else if (seconds < 86400) {
                return `${Math.floor(seconds / (60 * 60))} hour(s) ${Math.floor(getRemains(seconds / 60, 60))} minute(s) ${getRemains(seconds, 60)} second(s)`;
            } else {
                return `${Math.floor(seconds / (60 * 60 * 24))} day(s) ${Math.floor(getRemains(seconds / (60 * 60), 24))} hour(s) ${Math.floor(getRemains(seconds / 60, 60))} minute(s) ${getRemains(seconds, 60)} second(s)`;
            }
        }
        return ''
    }

    getSubmitButton() {
        if (this.state.calcType === 'standard') {
            return <Button key={'submit'} htmlType={'submit'} form={'iterationsForm'}>Add</Button>

        } else if (this.state.calcType === 'recommendation') {
            return <Popconfirm
                title={`The calculation takes around ${AVE_TIME - 1}-${Number(AVE_TIME) + 1} seconds per item. The execution of the scenario will approximately take around ${this.getApproximateDuration()}. Are you sure to continue?`}
                onCancel={null}
                okText="Yes"
                okButtonProps={{form:'iterationsForm', key: 'submit', htmlType: 'submit'}}
                cancelText="No"
            >
                <Button>Add</Button>
            </Popconfirm>
        }
        return null
    }

    render() {

        const constants = this.state.settings.constants;
        const options = constants && constants.service_factor_table.map((v,i) => <Option key={i}
                                                                                         value={i}>Service Level: {v.sl} - Customer Service Factor: {v.csf}
        </Option>)

        const scopeOptions = this.props.scopes && this.props.scopes.map((v,i) => <Option key={i} value={i}>{v.name} ({v.quantity || 0} items)</Option>)

        const initialValues = {
            csf: this.getTheIndexOfDefault(),
            s: 100,
            scope: 0,
            mad: 1,
            holding: 20,
            forecasting: 'naive',
            recalc_interval: 'never',
            ml: false,
            calculations_type: 'recommendation'
        }

        const typesOption = ['standard', 'recommendation'].map((v,i) => <Option key={i} value={v}>{this.props.calcTypeLabels[v]}</Option>)
        const recalcIntervalOption = ['never', 'month', 'quarter', 'halfYear', 'year'].map((v,i) => <Option key={i} value={v}>{this.props.calcTypeLabels[v]}</Option>)
        const forecastingOption = ['naive'].map((v,i) => <Option key={i} value={v}>{this.props.calcTypeLabels[v]}</Option>)
        const useMLOption = [true, false].map((v,i) => <Option key={i} value={v}>{this.props.calcTypeLabels[v]}</Option>)

        return (
            <Modal width={900} title="Add New Scenario"
                   visible={this.props.modalState}
                   okText="Add"
                   onCancel={this.handleCancel}
                   cancelText="Cancel"
                   footer={[
                       <Button onClick={this.handleCancel}>Cancel</Button>,
                       this.getSubmitButton()

                   ]}>
                <Form
                    onFinish={this.onFinish}
                    onFieldsChange={this.handleFieldChange}
                    initialValues={initialValues}
                    id="iterationsForm"
                >
                    <Form.Item
                        label="Scenario Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the scenario name!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Type"
                        name="calculations_type"

                        rules={[
                            {
                                required: true,
                                message: 'Please input the calculations type!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select type for the calculations"
                        >
                            {typesOption}
                        </Select>
                    </Form.Item> <Form.Item
                        label="Scope"
                        name="scope"

                        rules={[
                            {
                                required: true,
                                message: 'Please input the Scope!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select scope for the scenario"
                        >
                            {scopeOptions}
                        </Select>
                    </Form.Item>

                    {this.state.calcType === 'recommendation' && <>
                        <Form.Item
                            label="Forecasting Method"
                            name="forecasting"

                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the forecasting method!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select forecasting for the calculations"
                            >
                                {forecastingOption}
                            </Select>
                        </Form.Item>
                    </>}

                    {this.state.calcType === 'recommendation' && <>
                        <Form.Item
                            label="Use AIML Recommendations"
                            name="ml"

                            rules={[
                                {
                                    required: true,
                                    message: 'Please input if we should use the ml recommendation method!',
                                },
                            ]}
                        >
                            <Select
                            >
                                {useMLOption}
                            </Select>
                        </Form.Item>
                    </>}

                    {this.state.calcType === 'standard' && <> <Form.Item
                        label="Service Level"
                        name="csf"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the service level!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select a csf level"
                        >
                            {options}
                        </Select>
                    </Form.Item>
                        <Form.Item
                            label="Cost to place one order"
                            name="s"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the cost to place one order!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Mean Absolute Deviation"
                            name="mad"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the mean absolute deviation!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Holding Cost (%)"
                            name="holding"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the Holding Cost!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </>}
                    <Form.Item
                        label="Recalculation interval"
                        name="recalc_interval"

                        rules={[
                            {
                                required: true,
                                message: 'Please input the interval!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select interval"
                        >
                            {recalcIntervalOption}
                        </Select>
                    </Form.Item>


                </Form>
            </Modal>
        );
    }

}
