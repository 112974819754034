import React, {useEffect, useState} from 'react';
import {Col, Layout, message, Row, Spin} from 'antd';
import {DataCard} from "../../components/helpers/cards/Card";
import {connect} from "react-redux";
import { getSelectedExamples, getSelectedInfo, searchByMaterial} from "../../actions/Requests";
import * as Auth from "../../AuthService";
import axios from "../../api";
import {downloadExcel} from "../../helpers";
import {TablePanel} from "../../components/helpers/TablePanel";
import {InfoPanel} from "../../components/helpers/InfoPanel";
import {dict} from "../../Dashboard2";
import {show_obsoletes} from "../../properties";


export function StatPanel({type, onClick, data, title}) {
    const description = () => {
        if (show_obsoletes && type === 'obsoletes') {
            return null
        }
        // switch (type) {
        //         //     case 'obsoletes':
        //         //         return 'Items that have stock in more than 1 plant'
        //         //     case 'duplicates':
        //         //         return 'Items which are identified using the part number (have the similar one)'
        //         //     case 'no-bom':
        //         //         return 'Items that have no BOM associated with them'
        //         //     case 'insurance':
        //         //         return 'Items with a value exceeding USD 50,000'
        //         //     case 'slow-moving':
        //         //         return 'Items which had no movement in 3.5 years'
        //         //     default:
        //         //         return ''
        //         // }
        return <ul>{dict[type].map(v => <li>{v}</li>)}</ul>
    }

    const getTitle = title => {
        if (title === 'Obsoletes') {
            return show_obsoletes ? 'Obsoletes' : 'Common Stock'
        } else if (title === 'Insurance') {
            return 'Capital Spares'
        }
        return title
    }

    return <Row>
        <Col span={8} onClick={onClick}><InfoPanel  style={{height: 175}} title={`${getTitle(title)} Items Status At the Project Start`}
                                                                            info={data}/></Col>
        <Col span={8} onClick={() => getSelectedExamples(type)}><InfoPanel  style={{height: 175}} title={`${getTitle(title)} Items Status on 2022-08-04`}
                                                                            info={data}/></Col>

        <Col span={8} style={{padding: 15}}>
            {!(show_obsoletes && type === 'obsoletes') && <DataCard style={{minHeight: 175, maxHeight: 200, overflowY: 'scroll'}}>
                {description()}
            </DataCard>}
        </Col>
    </Row>
}

function DashboardCategoryLayout ({type, title,selected, total, selectedInfo, getSelectedInfo, getSelectedExamples, searchByMaterial }) {

    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({})

    const getData = () => {
        return selectedInfo
    }

    const getInfoValues = () => {
        const _id = window.location.pathname.split('/')[2]

        getSelectedInfo(type, _id)
    }

    const search = (query) => {
        if (query) {
            searchByMaterial(query, type)
        }
        else {
            getSelectedExamples(type, {current: 1, pageSize: 50})
        }
    }

    const _export = () => {
        message.info('Start exporting the category items')
        setLoading(true)
        const project_id = window.location.pathname.split('/')[2]
        const config = Auth.createConfig();
        config['responseType'] = 'blob';
        axios.get(`/projects/${project_id}/export/${type}`, config)
            .then(json => {
                downloadExcel(json.data, `${type === 'obsoletes' ? 'common-stock' : type} items.xlsx`)
            }).catch(error => {
            console.log(error)
        }).finally(() => setLoading(false))
    }

    const handleChange = (pagination, filters, sorters) => {
        setPagination(pagination)
        getSelectedExamples(type, pagination, filters, sorters)
    }

    useEffect(() => {
        getInfoValues()
    }, [type])

    console.log(selected)

    return <Layout.Content style={{padding: '0', backgroundColor: 'rgba(162,199,226, 0.05)'}}>
        <Spin spinning={loading}>
        <StatPanel type={type}
                   onClick={() => getSelectedExamples(type , {current: 1, pageSize: 50} )}
                   data={getData()} title={title}
        />
        <Row style={{padding: 15}}>
            {selected && <TablePanel pagination={pagination} total={total} export={_export} search={search} data={selected} handleChange={handleChange}/>}
        </Row>
        </Spin>
    </Layout.Content>

}

const mapStateToProps = state => {
    return {
        selectedInfo: state.selectedInfo,
        selected: state.selected,
        total: state.total
    }
}

export default connect(mapStateToProps, {
    getSelectedInfo,
    getSelectedExamples,
    searchByMaterial
})(DashboardCategoryLayout)
