import React from 'react';
import {Button, message} from "antd";
import api from "../../api";
import * as Auth from "../../AuthService";
import {downloadExcel} from "../../helpers";
import {DataCard} from "../helpers/cards/Card";

export function MMSTAReport () {

    const downloadReport = () => {
        const hide = message.loading('Creating and download MMSTA report...', 0);
        const projectId = window.location.href.split('/').reverse()[1]

        const config = Auth.createConfig();
        config['responseType'] = 'blob';

        api.get(`/projects/${projectId}/material-status/export`, config).then(json => {
            downloadExcel(json.data, `MMSTA report.xlsx`)
        }).catch(error => {
            console.log(error)
        }).finally(() => setTimeout(hide, 1))
    }


    return <DataCard title="Material Status Report" style={{margin:10}}>
        <Button onClick={downloadReport} >Download the report</Button>
    </DataCard>
}
