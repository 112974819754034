import React, {useEffect, useState} from 'react'
import {Tabs} from "antd";

import {getProjectId} from "../../helpers";
import {ScopesList} from "../scopes/ScopesList";
import {ItemsListTable} from "../scopes/ItemsListTable";

const {TabPane} = Tabs;

export function ScopesSettings(props) {

    const [projectId, setProjectId] = useState("")
    const [activeKey, setActiveKey] = useState("1")

    useEffect(() => {
        setProjectId(getProjectId(props.location))
    }, [])

    return <div style={{padding: 10}}>
        <Tabs activeKey={activeKey} onChange={setActiveKey}>
            <TabPane tab={'Scopes'} key={"1"}>
                <ScopesList activeKey={activeKey} projectId={projectId}/>
            </TabPane>
            <TabPane tab={'Items'} key={"2"}>
                <ItemsListTable projectId={projectId} activeKey={activeKey}/>
            </TabPane>
        </Tabs>
    </div>

}
