import React, {useEffect, useState} from "react";
import api from "../../api";
import {Button, Form, InputNumber, message, Select, Spin, Tag} from "antd";
import {DataCard} from "../helpers/cards/Card";
import {ModalWindow} from "../helpers/modals/ModalWindow";
import TextArea from "antd/es/input/TextArea";
import {TasksViewer} from "./TasksViewer";
import {ROP_MIN_LABEL} from "../../properties";
import {TagFilled} from "@ant-design/icons";
import {checkAllPermissions} from "../../helpers";

export const AssignApproval = ({
                                   type = 'material',
                                   projectId,
                                   setTaskId,
                                   itemInfo,
                                   currentTaskId,
                                   scope,
                                   shouldSelectWorkflow = true,
                                   closeApproval,
                                   onApprove,
                                   show = false,
                                   proposedMin = 0,
                                   proposedMax = 0,
                                   approvalStatus
                               }) => {

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [workflows, setWorkflows] = useState([])
    const [tasks, setTasks] = useState([])
    const [loading, setLoading] = useState(false);
    const [userTasks, setUserTasks] = useState([])
    const [scopeMode, setScopeMode] = useState('scenario')
    const [scenarios, setScenarios] = useState([])
    const [msg, setMsg] = useState('')
    const [allowSelect, setAllowSelect] = useState(shouldSelectWorkflow)

    const [user, setUser] = useState({})

    const closeModal = () => {
        closeApproval()
        setIsModalVisible(false)
    }

    const getScenarios = () => {

        const data = {
            pagination: {
                'current': null
            },
            _filters: {
                scope: [scope]
            }

        }

        if (projectId && scope) {
            api.post(`/projects/${projectId}/iterations`, data)
                .then(json => {
                    setScenarios(json.data.iterations)
                })
        }
    }

    useEffect(() => {
        if (show) {
            setIsModalVisible(true)
            form.setFieldsValue({
                min: proposedMin,
                max: proposedMax
            })
            setMsg('')
        }
    }, [show])

    const openModal = () => {
        setIsModalVisible(true)

    }

    const checkIfAdmin = () => {
        checkAllPermissions(['admin'])
            .then(() => setIsAdmin(true))
            .catch(() => setIsAdmin(false))
    }

    useEffect(() => {
        shouldSelectWorkflow && getWorkflows()
        getTasks()
        setMsg('')
        getUserTasks()
    }, [itemInfo, isModalVisible])

    useEffect(() => {
        getUser()
        getUserTasks()
        checkIfAdmin()

    }, [])

    useEffect(() => {
        setAllowSelect(shouldSelectWorkflow)
        setMsg('')
    }, [shouldSelectWorkflow]);

    useEffect(() => {
        if (type === 'scope') {
            getScenarios()
        }
    }, [type, scope])

    useEffect(() => {
        getUserTasks()
    }, [user]);

    const getWorkflows = () => {
        api.get(`/projects/${projectId}/workflows`)
            .then(json => setWorkflows(json.data.data))
    }

    const getTasks = () => {
        api.get(`/tasks/object_id/${itemInfo["material"]}/object_type/${type}`,)
            .then(json => {
                setTasks(json.data)
                if (setTaskId && json.data.length > 0) {
                    if (currentTaskId !== json.data[0].task_id) {
                        setTaskId(json.data[0].task_id)
                    }
                }
                approvalStatus(json.data.filter(v => v.status !== 'done').length > 0)
            })
    }

    const getUser = () => {
        api.get('/get_current_user').then(json => setUser(json.data))
    }

    const getUserTasks = () => {
        if (type && itemInfo['material']) {
            api.get(`/tasks/object_id/${itemInfo["material"]}/object_type/${type}/user/${user['email']}`,)
                .then(json => {
                    setUserTasks(json.data)
                })
        }
    }

    const [form] = Form.useForm()

    const getOptions = () => {
        return workflows ? workflows.map(((w, idx) => {
            return {
                'value': idx,
                'label': `${w.name} (Number of steps: ${w.steps.length})`
            }
        })) : []
    }


    const getScenarioOptions = () => {
        return scenarios ? scenarios.map(((s, idx) => {
            return {
                'value': idx,
                'label': `${s.iteration_name}`
            }
        })) : []
    }

    const handleSaveWithAutomated = values => {
        if (values['min'] > values['max']) {
            message.error(`ROP value must be less than Max value (Max Value = ${values['max']}) `)
            return;
        }
        setLoading(true)
        let rop_change = values['min'] - itemInfo.rop
        let max_change = values['max'] - itemInfo.max

        const data = {
            rop_change,
            max_change,
            'type': itemInfo['Type'],
            'criticality': itemInfo['cl_name'],

            workflow_info: {
                object: itemInfo['material'],
                object_type: 'material',
                project_id: projectId,
                material_object_id: itemInfo['_id'],

                min: values['min'],
                max: values['max'],
                comment: values['comment']
            }
        }

        const bodyFormData = new FormData();
        bodyFormData.append('json', JSON.stringify(data))


        api.post(`/tasks/automatic`, bodyFormData, {"Content-Type": "multipart/form-data"})
            .then(json => {
                if (Object.keys(json.data).includes('msg')) {
                    message.success(json.data.msg)
                    closeModal()
                } else {
                    setMsg('The system wasn\'t able to select the workflow automatically. Please choose one in order to proceed')
                    setWorkflows(json.data.data)
                    setAllowSelect(true)
                }
            })
            .finally(() => setLoading(false))
    }

    const handleSave = (values) => {
        setLoading(true)
        let req = {}
        if (values['min'] > values['max']) {
            message.error(`ROP value must be less than Max value (Max Value = ${values['max']}) `)
            return;
        }
        if (type === 'material') {
            message.info('Start the approving process for the material')
            workflows[values['workflow_idx']]['steps'][0]['comment'] = values['comment']
            req = {
                object: itemInfo['material'],
                object_type: 'material',
                project_id: projectId,
                material_object_id: itemInfo['_id'],
                steps: workflows[values['workflow_idx']]['steps'],
                workflow_id: workflows[values['workflow_idx']]['_id'],
                description: workflows[values['workflow_idx']]['name'],
                workflow_name: workflows[values['workflow_idx']]['name'],
                min: values['min'],
                max: values['max'],
                comment: values['comment']

            }

        }

        if (type === 'scope') {
            message.info('Start the approving process for the scope')
            req = {
                object: itemInfo['material'],
                object_type: 'scope',
                project_id: projectId,
                material_object_id: itemInfo['_id'],
                steps: workflows[values['workflow_idx']]['steps'],
                workflow_id: workflows[values['workflow_idx']]['_id'],
                description: workflows[values['workflow_idx']]['name'],
                workflow_name: workflows[values['workflow_idx']]['name'],

                comment: values['comment']

            }
            let min_max_mode = {}
            switch (scopeMode) {
                case 'fixed':
                    min_max_mode = {
                        type: 'fixed',
                        min: values['min'],
                        max: values['max'],
                    }
                    break;
            }

            req['mode'] = min_max_mode
        }

        const bodyFormData = new FormData();
        bodyFormData.append('json', JSON.stringify(req))

        api.post(`/tasks`, bodyFormData, {"Content-Type": "multipart/form-data"})
            .then(json => {
                if (Object.keys(json.data).includes('msg')) {
                    message.success(json.data.msg)
                    closeModal()
                }
            }).finally(() => setLoading(false))
    }

    const onOk = () => {
        form.submit()
    }

    const getCurrentTask = () => {
        return tasks.filter(x => x['status'] === 'active')
    }

    const getTitle = () => {
        const current = getCurrentTask()
        if (current.length === 0 || type === 'scope') {
            return 'Approval Status'
        } else {

            return `Approval Status (${ROP_MIN_LABEL} - ${current[0]['min']}, MAX - ${current[0]['max']})`
        }
    }

    const handleApprove = () => {
        getTasks()
        onApprove()
    }


    const onSave = values => {
        if (allowSelect) {
            handleSave(values)
        } else {
            handleSaveWithAutomated(values)
        }


    }

    const resetApproval = () => {
        message.info('Start resetting the approval...')
        api.get(`/projects/${projectId}/material/${itemInfo.material}/approve/reset`)
            .then(res => {
                message.success('The approve was successfully reset.')
                handleApprove()
            }).error(error => {
                message.error(error.msg)
        })
    }


    return <DataCard style={{width: '100%', margin: "0 5"}} title={getTitle()}>
        <ModalWindow modalProps={{
            maxHeight: '40vh',
            cancelButtonProps: {disabled: loading},
            okButtonProps: {disabled: loading, loading: loading}
        }} title={'Assign approval'} isModalVisible={isModalVisible}
                     onOk={onOk} onCancel={closeModal}>
            <Spin spinning={loading}>
                <Form onFinish={onSave} form={form} style={{height: 275}}>
                    {msg && <Tag color="error" style={{marginBottom: 10}}>{msg}</Tag>}
                    {allowSelect && <Form.Item label={'Select the Workflow'} name={'workflow_idx'}>
                        <Select options={getOptions()}/>
                    </Form.Item>}
                    {type === 'material' && <Form.Item label={`Input the ${ROP_MIN_LABEL}`} name={'min'}>
                        <InputNumber/>
                    </Form.Item>}
                    {type === 'material' && <Form.Item label={'Input the MAX'} name={'max'}>
                        <InputNumber/>
                    </Form.Item>}
                    {type === 'scope' && <div>
                        <Form.Item label={'Select the scenario'} name={'scenario'}>
                            <Select options={getScenarioOptions()}/>
                        </Form.Item>
                    </div>}
                    <Form.Item label={'Input the comment'} name={'comment'}>
                        <TextArea rows={4}/>
                    </Form.Item>
                </Form>
            </Spin>
        </ModalWindow>
        {(tasks && tasks.filter(t => t.status !== 'done').length === 0) &&
            <div>
                <Button style={{margin: 5}} onClick={openModal}>Start the approval process</Button>
                <Button style={{margin: 5}} onClick={resetApproval}>Reset the approval process</Button></div>}
        {(tasks && tasks.filter(t => t.status !== 'done').length > 0) &&
            <TasksViewer onApprove={handleApprove} current={getCurrentTask()}
                         userInfo={user}
                         isAdmin={isAdmin}
                         type={type}
                         projectId={projectId}
                         itemInfo={itemInfo}
                         userTasks={userTasks}/>}
    </DataCard>
}
