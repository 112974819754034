import api from "../../api";
import {message} from "antd";
import {StandardModalForm} from "../helpers/modals/StandardModalForm";
import React, {useEffect} from "react";

export function AddScopeModal({isModalVisible, setIsModalVisible, projectId, isSuperAdmin, scope, setSelectedScope}) {

    const closeEditModal = () => {
        setIsModalVisible(false)
        setSelectedScope({})
    }

    const saveEditValues = (values) => {
        if (!!scope && Object.keys(scope).length > 0) {

            let data = {...scope, ...values}
            console.log(data)

            api.post(`/projects/${projectId}/scopes/edit`, data)
                .then(() => {
                    message.success(`The scope ${values.name} was successfully edited`)
                    closeEditModal()
                })
                .catch(() => message.error('Something went wrong during saving! Please try again later.'))

        } else {
            if (!Object.keys(values).includes('type')) {
                values['type'] = 'user-defined'
            }
            api.post(`/projects/${projectId}/scopes/add`, values)
                .then(() => {
                    message.success(`The scope ${values.name} was successfully created`)
                    closeEditModal()
                })
                .catch(() => message.error('Something went wrong during saving! Please try again later.'))
        }
    }

    const fields = [
        {label: 'Scope Name', name: 'name', _type: 'string', message: 'Error', required: true},
        {label: 'Scope Description', name: 'description', _type: 'long_text', message: 'Error', required: true},
    ]

    let values = {}
    if (!!scope) {
        values = {...scope}
    }

    return <StandardModalForm fields={fields} isModalVisible={isModalVisible} closeModal={closeEditModal}
                              initialValues={values} save={saveEditValues}/>

}
