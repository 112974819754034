import React, {Component, useEffect, useMemo, useState} from "react";
import {Card, Col, Row, Statistic, Table, Tabs, Tree} from "antd";
import {DoughnutGraph} from "../components/helpers/DoughnutGraph";
import {ImprovementStatistics} from "../components/helpers/ImprovementStatistics";
import api from "../api";

import axios from 'axios'

import * as Auth from '../AuthService'
import {getProjectId} from "../helpers";
import Search from "antd/es/input/Search";
import {DataCard} from "../components/helpers/cards/Card";
import {properties} from "../properties";
import {Checkbox} from "antd/es";

const { TabPane } = Tabs;

class DataMaturityClassInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            attrs: [],
            totalWeight: 0
        }
    }

    componentDidMount() {
        this.getAttributes(this.props.className)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.className !== this.props.className) {
            this.getAttributes(this.props.className)
        }
    }

    getAttributes = className => {
        axios.get(`${properties.dmLink}/api/attributes?class=${className.toUpperCase()}`, Auth.createConfig()).then(json => {
            let weight = json.data.items.reduce((sum, item) => sum + (item.required ? item.weight : 0), 0)
            this.setState({attrs: json.data.items, totalWeight: weight})
            }
        )
            .catch(error => {
                console.log(error)
            })
    }

    getColumnsExamples = () => {
        return [
            {
                title: 'Valve MM Items',
                dataIndex: 'material',
                key: 'material',
            },
            {
                title: 'Scores',
                dataIndex: 'scores',
                key: 'scores',
            },
            {
                title: 'Number of Attributes Present in the Description',
                dataIndex: 'NumOfAttrs',
                key: 'NumOfAttrs',
            }
        ];
    }

    getColumnsConfig = () => {
        return [
            {
                title: 'Attribute',
                dataIndex: 'attr',
                key: 'attr',
            },
            {
                title: 'Weighting (%)',
                dataIndex: 'weight',
                key: 'weight',
            },
            {
                title: 'Mandatory',
                dataIndex: 'mandatory',
                key: 'mandatory',
            },
            {
                title: 'Presence',
                dataIndex: 'presence',
                key: 'presence',
            }
        ];
    }

    getRowsConfig = () => {
        const rows = this.state.attrs.map((v, i) => {
            return {
                'attr': v.attributeName,
                'weight': <Statistic valueStyle={{fontSize: 14}} value={this.state.totalWeight > 0 && v.required ? (v.weight / this.state.totalWeight * 100).toFixed(1) : ''} suffix={v.required && '%'} />,
                'mandatory': <Checkbox disabled checked={v.required}/>
            }
        })
        rows.push({
            'attr': 'Total',
            weight: <Statistic valueStyle={{fontSize: 14}} value={100} suffix={'%'} />,
        })
        return rows;
    }

    getRowsExamples = (className) => {
        if (className === 'Valve Gate') {
            return [
                {
                    'material': 'Valve 1',
                    'scores': <Statistic valueStyle={{fontSize: 14}} value={50} suffix='%'/>,
                    NumOfAttrs: 8
                },
                {
                    'material': 'Valve 2',
                    'scores': <Statistic valueStyle={{fontSize: 14}} value={70} suffix='%'/>,
                    NumOfAttrs: 10
                },
                {
                    'material': 'Valve 3',
                    'scores': <Statistic valueStyle={{fontSize: 14}} value={35} suffix='%'/>,
                    NumOfAttrs:5
                },
                {
                    'material': 'Valve 4',
                    'scores': <Statistic valueStyle={{fontSize: 14}} value={45} suffix='%'/>,
                    NumOfAttrs: 7
                }
            ]
        }
    }

    render() {

        return (
            <Card>
                <Tabs>
                    <TabPane tab="Maturity" key="1">
                        <Table style={{overflowY: 'auto', height: '35vh'}} size={'small'} pagination={false} columns={this.getColumnsConfig()} dataSource={this.getRowsConfig(this.props.className)}/>
                    </TabPane>
                    <TabPane tab="Examples" key="2">
                        <Table columns={this.getColumnsExamples()} dataSource={this.getRowsExamples(this.props.className)}/>
                    </TabPane>
                </Tabs>
            </Card>
        );
    }

}


class DataMaturityClassPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            tree: []
        }
    }

    getTreeData() {
        let result = [{
            title: <div>Disciplines</div>,
            key: 'root',
            children: []
        }]

        let data = this.state.tree;

        for (let pn in data) {
            result[0].children.push({
                title: <div className={this.props.selected === pn ? 'item-list-selected' : ''}
                            onClick={() => this.props.handleSelect(pn)} >{pn} [{data[pn].length}]</div>,
                key: pn,
                searchable: pn,
                children: data[pn].map(v => {
                    return {
                        title: <div className={this.props.selected === v ? 'item-list-selected' : ''}
                                    onClick={() => this.props.handleSelect(v)}>{v}</div>,
                        searchable: v,
                        key: v
                    }
                })
            })
        }

        return result
    }

    componentDidMount() {
        this.getTree();
    }

    getTree = () => {
        api.get(`/projects/${this.props.projectId}/data-maturity/disciplines`, Auth.createConfig())
            .then(json => {
                this.setState({tree: json.data})
            })
    }

    render() {

        const dataList = []

        const getDataList = (data) => {
            for (let i = 0; i < data.length; i++) {
                const node = data[i];
                const { key } = node;
                dataList.push({
                    key,
                    searchable: key,
                    title: key,
                });
                if (node.children) {
                    getDataList(node.children);
                }
            }
        }

        getDataList(this.getTreeData())

        return (
            <Row>
                <Col span={8}>
                    <Card style={{height: '55vh'}}>
                        <DMTree dataList={dataList} defaultData={this.getTreeData()} />
                    </Card>
                </Col>
                <Col span={16}>
                    {this.props.selected && <Card style={{height: '55vh'}} >
                        <Row>
                            <Col span={24}>
                                <DataMaturityClassInfo className={this.props.selected} />
                            </Col>
                        </Row>
                    </Card>}
                </Col>
            </Row>
        );
    }

}

const DMTree = ({dataList = [], defaultData = []}) => {

    const getParentKey = (key, tree) => {
        let parentKey;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some((item) => item.key === key)) {
                    parentKey = node.key;
                } else if (getParentKey(key, node.children)) {
                    parentKey = getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };

    const [expandedKeys, setExpandedKeys] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const onExpand = (newExpandedKeys) => {
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(false);
    };
    const onChange = (e) => {
        const { value } = e.target;
        const newExpandedKeys = dataList
            .map((item) => {
                if (item.searchable.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                    return getParentKey(item.key, defaultData);
                }
                return null;
            })
            .filter((item, i, self) => item && self.indexOf(item) === i);
        setExpandedKeys(newExpandedKeys);
        setSearchValue(value);
        setAutoExpandParent(true);
    };

    useEffect(() => {
        if (expandedKeys.length === 0) {
            setExpandedKeys(['root'])
        }
    }, [dataList])

    const treeData = useMemo(() => {
        const loop = (data) =>
            data.map((item) => {
                const strTitle = item.title;
                const title = (
                        <span>{strTitle}</span>
                    );
                if (item.children) {
                    return {
                        title,
                        key: item.key,
                        children: loop(item.children),
                    };
                }
                return {
                    title,
                    key: item.key,
                };
            });
        return loop(defaultData);
    }, [searchValue, dataList]);
    return (
        <div>
            <Search
                style={{
                    marginBottom: 8,
                }}
                placeholder="Search"
                onChange={onChange}
            />
            <Tree
                defaultExpandedKeys={['root']}
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                treeData={treeData}
            />
        </div>
    );
}

export class DataMaturityPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            overview: {
                start: 0,
                now: 0
            },
            classes: [],
            selectedInfo: {
                start: 0,
                now: 0,
                total: 0
            },
            selected: ''
        }
    }

    getClasses = projectId => {
        api.get(`/projects/${projectId}/data-maturity/classes`, Auth.createConfig())
            .then(json => {
                this.setState({classes: json.data})
            })
    }

    getOverview = projectId => {
        api.get(`/projects/${projectId}/data-maturity/overall`, Auth.createConfig())
            .then(json => {
                this.setState({overview: json.data})
                console.log()
            })
    }

    getClassInfo = className => {
        const project_id = getProjectId(this.props.location)
        api.get(`/projects/${project_id}/data-maturity/classes/overview?class=${className}`).then(
            json => this.setState({selectedInfo: json.data})
        )
    }

    handleSelect = selected => {
        this.setState({selected: selected})
        this.getClassInfo(selected)
    }

    componentDidMount() {
        const project_id = getProjectId(this.props.location)
        this.getOverview(project_id);
        this.getClasses(project_id)
    }

    render() {
        const project_id = getProjectId(this.props.location)
        return (
            <div style={{padding: 10}}>
                <Row>
                    <Col span={1}/>
                    <Col span={10}>
                        <DataCard title={<div className='text-center'>Overall Maturity</div>}
                              style={{margin: 10}}>
                            <Row>
                                <Col span={8}>
                                    <Statistic className='text-center' suffix="%" title='At the Start' value={this.state.overview.start}/>
                                </Col>
                                <Col span={8}>
                                    <Statistic className='text-center' suffix="%" title='Now' value={this.state.overview.now}/>
                                    <ImprovementStatistics value={this.state.overview.now - this.state.overview.start} />
                                </Col>
                                <Col span={8}>
                                    <Statistic className='text-center' suffix="%" title='Target' value={80}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='text-center' span={8}>
                                    <DoughnutGraph data={[this.state.overview.start, 100 - this.state.overview.start]}
                                                   height={60}
                                                   legendLabels={['Maturity', 'Total']}
                                                   labels={['Maturity', 'Total']}/>
                                </Col>
                                <Col span={8}>
                                    <DoughnutGraph data={[this.state.overview.now, 100 - this.state.overview.now]}
                                                   height={60}
                                                   legendLabels={['Maturity', 'Total']}
                                                   labels={['Maturity', 'Total']}/>
                                </Col>
                                <Col span={8}>
                                    <DoughnutGraph data={[80, 20]}
                                                   height={60}
                                                   legendLabels={['Maturity', 'Total']}
                                                   labels={['Maturity', 'Total']}/>
                                </Col>
                            </Row>
                        </DataCard>
                    </Col>
                    <Col span={2}/>
                    <Col span={10}>
                        {this.state.selected && <DataCard title={<div className='text-center'>{this.state.selected} Maturity ({this.state.selectedInfo.total} items)</div>}
                               style={{margin: 10}}>
                            <Row>
                                <Col span={8}>
                                    <Statistic className='text-center' suffix="%" title='At the Start'
                                               value={this.state.selectedInfo.start}/>
                                </Col>
                                <Col span={8}>
                                    <Statistic className='text-center' suffix="%" title='Now'
                                               value={this.state.selectedInfo.now}/>
                                    <ImprovementStatistics
                                    value={this.state.selectedInfo.now - this.state.selectedInfo.start}/>

                                </Col>
                                <Col span={8}>
                                    <Statistic className='text-center' suffix="%" title='Target' value={80}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='text-center' span={8}>
                                    <DoughnutGraph data={[this.state.overview.start, 100 - this.state.overview.start]}
                                                   height={60}
                                                   legendLabels={['Maturity', 'Total']}
                                                   labels={['Maturity', 'Total']}/>
                                </Col>
                                <Col span={8}>
                                    <DoughnutGraph data={[this.state.overview.now, 100 - this.state.overview.now]}
                                                   height={60}
                                                   legendLabels={['Maturity', 'Total']}
                                                   labels={['Maturity', 'Total']}/>
                                </Col>
                                <Col span={8}>
                                    <DoughnutGraph data={[80, 20]}
                                                   height={60}
                                                   legendLabels={['Maturity', 'Total']}
                                                   labels={['Maturity', 'Total']}/>
                                </Col>
                            </Row>
                        </DataCard>}
                    </Col>
                    <Col span={1}/>
                </Row>
                <DataMaturityClassPanel classes={this.state.classes}
                                        selected={this.state.selected}
                                        selectedInfo={this.state.selectedInfo}
                                        handleSelect={this.handleSelect}
                                        projectId={project_id}/>

            </div>
        );
    }

}
