import React from "react";
import { Line } from 'react-chartjs-2';
import {properties} from "../../properties";


export function CorstonGraph ({data, labels, colors=[], legendFields, height, titleLabels}) {

    const options = {
        scales: {
            y: {
                ticks: {
                    min: 0,
                    stepSize: 1
                }
            }
        }
    };

    const getChartData = () => {
        return {
            labels: data.map(s => s['date']),
            datasets: labels.map((v, i) => {
                return {
                    label: legendFields[i],
                    data: data.map(s => s[v] !== '' ? s[v] : null),
                    borderColor: colors[i] || 'black',
                    backgroundColor: colors[i] || 'black',
                    fill: false,
                }
            })
        };
    }

    return (
        <Line style={{height: height}} data={getChartData()} options={options} />
    )

}
