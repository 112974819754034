import {loadUIConfig} from "@digatex/digatex-ui-lib";
import PicklesConfig from './config/ui-config-pickles.json'
import PTTConfig from './config/ui-config-ptt.json'

export const properties = {
    apiUrl: process.env.REACT_APP_HOST,
    aiUrl: process.env.REACT_APP_AI,
    ihLink: process.env.REACT_APP_IH_LINK,
    dmLink: process.env.REACT_APP_DM_LINK,
    khLink: process.env.REACT_APP_KH_LINK,
    reportingLink: process.env.REACT_APP_REPORTING_LINK,
    analyticsLink: process.env.REACT_APP_WB_LINK,
    assetHubLink: process.env.REACT_APP_EH_LINK,
    version: process.env.REACT_APP_WB_VERSION,
    landingHeaderName: process.env.REACT_APP_LANDING_NAME || 'SIMS Platform',

    kbrColors: {
        pantone301Blue: "#004987",
        lightBlue: "#A2C7E2",
        yellow: "#ffda00",
        dark: "#00205C",
        green: "#00783F",
        black:"#231f20",
        vividCeruleanBlue: "#007fc5",
        acajouMarron: "#4c212a"

    },

    startColors: [
        "#007fc5",
        "#A2C7E2",
    ],

    targetColors: [
        "#007fc5",
        "#A2C7E2",
    ],

    currentColors: [
        "#007fc5",
        "#A2C7E2",
    ]
};

export const KEYCLOAK_AUTH_SERVER_URL = process.env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL
export const OWNER = process.env.REACT_APP_CLIENT_NAME
export const showOnDashboard = process.env.REACT_APP_DEFAULT_OPERATOR_SHOW_NAME || 'Client'

export const show_obsoletes =  process.env.REACT_APP_SHOW_OBSOLETES || false

export const START_CROSTON = process.env.REACT_APP_START_CROSTON || '2021-09'

export const projectCurrency = process.env.REACT_APP_CURRENCY || 'USD'

export const DEFAULT_OPERATOR = process.env.REACT_APP_DEFAULT_OPERATOR
export const ROP_MIN_LABEL = process.env.REACT_APP_ROP_MIN_LABEL || 'ROP'

export const AVE_TIME = process.env.REACT_APP_AVE_TIME || 4

export const SHOW_STANDARD_RECOMMENDATIONS = process.env.REACT_APP_SHOW_STANDARD_RECOMMENDATIONS || false

export const DEFAULT_FORECAST_END = process.env.REACT_APP_DEFAULT_FORECAST_END || '2030-04'

const operatorProjectPairs = process.env.REACT_APP_OPERATOR_TO_PROJECT_MAPPING?.split(',') || []
const projectToOperatorMap = new Map(operatorProjectPairs.map(pair => pair.split(':').reverse()))

export function projectToOperator(projectId) {
    return projectToOperatorMap.get(projectId)
}

export let UI_CONFIG;

if (['kbr', 'digatex'].includes(OWNER)) {
    UI_CONFIG = loadUIConfig()
    UI_CONFIG.header.logo.title = 'Workbench'
} else {
    switch (OWNER) {
        case 'pickles': {
            UI_CONFIG = PicklesConfig;
            UI_CONFIG.header.logo.version = process.env.REACT_APP_PROJECT_VERSION
            break;
        }
        case 'ptt': {
            UI_CONFIG = PTTConfig;
            UI_CONFIG.header.logo.version = process.env.REACT_APP_PROJECT_VERSION
            break;
        }
        default:
            console.error('Failed to load UI config: the client is unknown');

    }
}
